import React from 'react';
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import blog2 from '../../../assets/img/blog2.webp'
import { FaRegUser } from "react-icons/fa";



function secondBlog() {
  return (
    <div className="tsavo">
      <Header></Header>

      <div className='blogs-banner'> 
        <img src={blog2} alt="blogs"/>
        <div className='blog-user' style={{padding:'0% 20% 0% 20%'}}>
                            <FaRegUser />
                            <div className='blog-usercontent' >
                                <h3 style={{color:'brown', fontStyle:'oblique'}}>Salama Ahmed</h3>
                                <p style={{marginTop:'0.1rem'}}>Frid Jan 20 2024</p>
                            </div>

                            </div>

       </div>


       <div className='blog-text'>
        <h2 style={{fontWeight:'700'}}>A Symbol of Time and Travel</h2>
        <p style={{marginTop:'1.5rem'}}>In the vast tapestry of human existence, time is the one constant thread that weaves through every moment, guiding our journey through life's adventures. Like the sands in an hourglass, time flows ceaselessly, marking the passage of our experiences and shaping our memories.
           </p>
        <p>Within the confines of its elegant glass frame lies a profound symbolism that resonates deeply with the essence of travel.</p >
     <p style={{marginTop:'1rem'}}>As travelers, we are intimately acquainted with the passage of time. From the bustling streets of ancient cities to the tranquil shores of remote islands, every destination holds within it a story of its own, waiting to be unravelled in the timeless embrace of exploration. Just as the hourglass measures the 
     finite nature of time, so too does travel remind us of the fleeting moments we share with the world around us.</p>

    <p style={{marginTop:'1rem'}}> With each grain of sand that trickles through the narrow neck of the hourglass, we are reminded of the impermanence of our journeys. Yet, within this fleeting window lies the promise of adventure, beckoning us to seize the present moment
     and immerse ourselves in the tapestry of cultures, landscapes, and experiences that await us.</p>

    <p style={{marginTop:'1rem'}}>As we traverse the globe, our paths intersect with those of fellow travelers, each one leaving an indelible mark upon the sands of time. In the timeless dance of exploration, we find solace in 
    the shared stories of those who have come before us, and inspiration in the promise of new horizons yet to be discovered.</p>

    <p style={{marginTop:'1rem'}}>In the delicate balance of past and future, the hourglass stands as a silent sentinel, reminding us to cherish the moments we have been given and to embrace the unknown with open hearts and adventurous spirits. For in the ever-shifting sands of time, we find the true essence of travel – a journey of discovery,
     a celebration of the present, and a testament to the timeless allure of exploration.</p>
     
    <p style={{marginTop:'1rem'}}>So let us raise our glasses to the hourglass, that timeless symbol of time and travel, and toast to the adventures that lie ahead. For in the delicate balance of past and future, we find the true measure of our wanderlust – not
     in the miles we travel, but in the moments that take our breath away.</p>



    </div>

      <Footer></Footer>
    </div>
  );
}

export default secondBlog;