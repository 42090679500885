import React from "react";
import './speaker.css';

import speaker1 from '../../../assets/img/hotel.webp';
import speaker2 from '../../../assets/img/tour-img01.webp';
import speaker3 from '../../../assets/img/tour-img02.webp';
import speaker4 from '../../../assets/img/flight.webp';



function Speaker()  {
    return  (
        <div className="speaker">

            <div className="speakerTitle">Edgeway <span className='speakers'>Travel</span> Experience</div>

            <div className='speakerContainer'>
                <div className='speakerItem'>
                    <img src={speaker1} alt=" Arthur" />
                    <div className="speakerName">Destinations</div>
                    <div className="topic">It's the heart of Edgeway travel website, featuring a diverse array of exotic destinations packed with cultural experiences and natural wonders to be explored worldwide.</div>
                </div>

                <div className='speakerItem'>
                    <img src={speaker2} alt="Barbara" />
                    <div className="speakerName">Experiential Travel</div>
                    <div className="topic">It opens the door to uncharted and exhilarating experiences, where each journey is a delightful surprise waiting to be uncovered and cherished and unforgettable adventure.</div>
                </div>

                <div className='speakerItem'>
                    <img src={speaker4} alt="Cliff" />
                    <div className="speakerName">Immigration Advisory</div>
                    <div className="topic">Our advisory services offer comprehensive guidance and support, ensuring smooth transitions across borders. </div>
                </div>
                <div className='speakerItem'>
                    <img src={speaker3} alt="Cliff" />
                    <div className="speakerName">Special Visa Program</div>
                    <div className="topic">
Our exclusive program offers expedited visa processing, personalized assistance, and tailored travel itineraries.</div>
                </div>
            </div>

        </div>
    )
}

export default Speaker