export const eventsData = [
    {
        id: 1,
        image: '/images/masterclass.webp',
        title: 'Global Exploration',
        desc: " Embark on a journey of discovery with Edgeway Travel's Global Exploration. Dive into a world of diverse cultures, breathtaking landscapes, and unforgettable experiences. ",
        // Register: 'https://ziqweb.hustlesasa.shop/?product=34823',
    },
    {
        id: 2,
        image: '/images/espresso.webp',
        title: 'Dream Holiday',
        desc: " Begin an unforgettable journey with Edgeway Travel, where we craft extraordinary experiences tailored to your wildest dreams. ",
        // Register: '#',
    },
    {
        id: 3,
        image: '/images/event-4.webp',
        title: 'Explore the world',
        desc: "Hear firsthand accounts from fellow travelers, uncover insider tips for exploring each destination, and immerse yourself in the beauty of global exploration. Whether you seek adrenaline-pumping adventures or serene cultural encounters, Edgeway Travel invites you to explore the world and create memories that last a lifetime.",
        // Register: '#',
    },
    {
        id: 4,
        image: '/images/event-2.webp',
        title: 'Why travel with Edgeway',
        desc: " Whether it's lounging on pristine beaches, exploring vibrant cities, or immersing yourself in breathtaking natural landscapes, our expert team curates bespoke itineraries to turn your dream holiday into a reality. From luxurious accommodations to personalized excursions, let us guide you towards the ultimate getaway that exceeds your expectations and leaves you with memories to cherish for a lifetime.",
        // Register: '#',
    },

    {
        id: 5,
        image: '/images/event-1.webp',
        title: 'Importace of travelling',
        desc: "Exploring new destinations fosters personal growth, broadens perspectives, and promotes cultural understanding. Traveling allows individuals to break out of routine, embrace diversity, and create lasting memories. At Edgeway Travel, we believe in the transformative power of exploration, inspiring travelers to seek new experiences and connect with the world in meaningful ways.",
        // Register: '#',
    },

    {
        id: 6,
        image: '/images/event-3.webp',
        title: 'Let us travel',
        desc: "Dive into immersive travel experiences, discover hidden gems, and ignite your passion for exploration as we share firsthand accounts and expert insights to inspire your next journey. ",
        // Register: '#',
    },

];
