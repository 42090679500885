import React, { useState } from 'react';
import "./immigration.css"
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css';

import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { Carousel } from 'react-responsive-carousel';
import Caroussel from 'react-multi-carousel';
import zeitun from '../../assets/img/zeitun.webp'
import susan from '../../assets/img/susan.webp'
import lady from '../../assets/img/lady.webp'
import wanyama from '../../assets/img/wanyama.webp'
import Immigrationad from '../../assets/img/immigration.webp'
import flyer from '../../assets/img/flyer.webp'
import visa from '../../assets/img/visa.webp'
import canada from '../../assets/img/canada.webp'
import kenya from '../../assets/img/kenya.webp'
import europe from '../../assets/img/europe.webp'
import pari from '../../assets/img/pari.jpg'
import phil from '../../assets/img/phill.jpg'
import dubai from '../../assets/img/dubai.jpg'
import malaysia from '../../assets/img/malaysia.jpg'
import tanzania from '../../assets/img/ngoro.avif'
import egypt from '../../assets/img/egypt.jpg'
import morocco from '../../assets/img/morroco.jpg'
import japan from '../../assets/img/japan.jpg'
import greenland from '../../assets/img/greenland.jpg'





function Immigration() {

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };
    return(




        <div className='immigration'>
            <Header/>
            <div className='trial' >


            <Carousel showswArrows={true} showStatus={false}  >
        <div>
          <div className='first'>
            <img src={flyer} alt="Image 1" />
            <div class="left-aligned">
              <h1 style={{marginBottom:"20px"}}>Begin Your dream</h1>
              <p>Assisting the immigration sector entities in effectively </p>
              <p>managing transformative trends and emerging technologies.</p>
              <a
                href
                target="_blank"
                rel="noopener noreferrer"
                className="view-pdf-button"
                style={{
                  textDecoration: 'none',
                  display: 'inline-block',
                  padding: '10px 20px',
                  background: "#761034" ,
                  borderRadius: '50px',
                  cursor: 'pointer',
                  transition: 'background 0.3s ease',
                  color: "whitesmoke",
                  marginTop: "40px"
                }}
              >
                Consult with us
              </a>
            </div>
          </div>
        </div>
        <div>
          <img src={visa} alt="Image 2" />
        </div>
        <div>
          <img src={Immigrationad} alt="Image 3" />
        </div>
      </Carousel>
      </div>


      <div className='immigration-text'>
        <h2>CANADA DREAM - IMMIGRATING ABROAD</h2>
        <p style={{marginTop:'3rem'}}>In recent years, a growing number of young individuals have been exploring the possibility of obtaining permanent residency (PR) in Canada. While some have achieved success, others have faced challenges, and a significant portion remains uncertain about the process. 
            Online platforms, particularly Google and YouTube, are inundated with a plethora of information and personal anecdotes regarding the Canadian PR journey. </p>
        <p>In such a scenario, the most reliable approach is to hear directly from those who have experienced the Canadian PR process. Despite the abundance of online content, it is essential to discern reliable sources and gain insights from individuals who have successfully navigated the complexities of the application. 
                By focusing on commonalities and seeking advice from those with firsthand experience, aspirants can make informed decisions and increase their chances of a successful Canadian PR journey.</p>
     <h4 style={{marginTop:'3rem'}}>1. Understand the Different Immigration Options:</h4>
     <p style={{marginTop:'1rem'}}>There are many different immigration pathways, each with its own requirements and eligibility criteria. Some common options include:</p>
        <li>Work visas: Allow you to work in a foreign country for a specific period.</li>
        <li>Student visas: Allow you to study in a foreign country.</li>
        <li>Family visas: Allow you to join family members who are already citizens or permanent residents of a foreign country.</li>
        <li>Investment visas: Allow you to immigrate to a foreign country by investing a certain amount of money.</li>
        <li>Refugee status: Available to individuals who have fled their home country due to persecution or violence.</li>
    <h4 style={{marginTop:'3rem'}}>2. Research the Requirements for Your Chosen Destination:</h4>
    <p style={{marginTop:'1rem'}}>Once you've identified a potential immigration pathway, you'll need to research the specific requirements for your chosen destination.
         This information can usually be found on the government website of the country you're interested in immigrating to.</p>
    <h4 style={{marginTop:'3rem'}}>3. Consider Consulting an Immigration Lawyer:</h4>
    <p style={{marginTop:'1rem'}}>Immigration law is complex, and the specific requirements can vary depending on your individual circumstances. Consulting with 
        an immigration lawyer can help you navigate the process and ensure that you're meeting all of the requirements.</p>
    <h4 style={{marginTop:'3rem'}}>4. Be Wary of Scams:</h4>
    <p style={{marginTop:'1rem'}}>There are many scams targeting immigrants, so be careful about who you trust with your information and money. Only deal with reputable organizations and individuals.</p>
    <p>Here are some additional resources that you may find helpful:</p>
    <p>The United Nations High Commissioner for Refugees (UNHCR): <span><Link  to="https://www.unhcr.org/" style={{color:'#761034'}}> [https://www.unhcr.org/]</Link></span></p>
    <p>The International Organization for Migration (IOM): <span><Link  to="https://www.iom.int/" style={{color:'#761034'}}> [https://www.iom.int/]</Link></span></p>
    <p>The U.S. Citizenship and Immigration Services (USCIS):  <span><Link   to="https://www.uscis.gov/" style={{color:'#761034'}}>[https://www.uscis.gov/]</Link></span></p>
    <p>The Canadian government's immigration website: <span><Link  to="https://lnkd.in/g7QuZRv/" style={{color:'#761034'}}>[https://lnkd.in/g7QuZRv]</Link></span></p>


    </div>

    <h2 style={{marginLeft:'4em'}}>Embark on Further Adventures with Edgeway Travel;</h2>


    <Caroussel responsive={responsive} >

<div className="ed-articles-card">
  <img src={kenya} alt="articles"  className="ed-articles-card-pic" />
  <h3>Kenya</h3>
  <p>Kenya, known as the 'Jewel of East Africa,' captivates visitors with its unparalleled natural beauty and rich wildlife.Experience the breathtaking beauty of Kenya.
    </p>
</div>

<div className="ed-articles-card" >
  <img src={canada} alt="articles"  className="ed-articles-card-pic"/>
  <h3>Canada</h3>
<p>Canada offers a captivating blend of breathtaking natural landscapes, vibrant cities, and diverse cultural experiences, making it an irresistible destination for travelers worldwide.</p>
</div>

<div className="ed-articles-card">
  <img src={europe} alt="articles"  className="ed-articles-card-pic" />
<h3>Europe</h3>
  <p>
Europe offers a captivating blend of rich history, diverse cultures, and stunning landscapes, making it an irresistible destination for travelers seeking unforgettable experiences.</p>
</div>

<div className="ed-articles-card">
  <img src={japan} alt="articles"  className="ed-articles-card-pic" />
  <h3>Japan</h3>
  <p>Japan offers a captivating blend of ancient tradition and futuristic innovation, inviting travelers to immerse themselves in its
     rich cultural heritage.</p>
</div>


</Caroussel>
<Caroussel responsive={responsive} >

<div className="ed-articles-card">
  <img src={egypt} alt="articles"  className="ed-articles-card-pic" />
  <h3>Egypt</h3>
  <p>Experience the timeless allure of Egypt's ancient wonders, from the majestic pyramids to the storied banks of the Nile, where history and adventure converge.</p>
</div>

<div className="ed-articles-card" >
  <img src={phil} alt="articles"  className="ed-articles-card-pic"/>
  <h3>Phillipines</h3>
<p>The Philippines offers a captivating blend of stunning natural landscapes, vibrant cultural experiences, and warm hospitality, making it an irresistible destination.</p>
</div>

<div className="ed-articles-card">
  <img src={pari} alt="articles"  className="ed-articles-card-pic" />
<h3>Paris</h3>
  <p>
Paris, the City of Light, enchants visitors with its iconic landmarks, exquisite cuisine, and unparalleled art and culture.</p>
</div>

<div className="ed-articles-card">
  <img src={greenland} alt="articles"  className="ed-articles-card-pic" />
  <h3>Greenland</h3>
  <p>Greenland offers unparalleled opportunities for adventurous travelers to explore vast icy landscapes, encounter unique wildlife, 
    and immerse themselves in indigenous cultures.</p>
</div>


</Caroussel>


<Caroussel responsive={responsive} >

<div className="ed-articles-card">
  <img src={morocco} alt="articles"  className="ed-articles-card-pic" />
  <h3>Morocco</h3>
  <p>Morocco offers an enchanting blend of vibrant markets, exotic landscapes,
     and rich cultural heritage, making it a captivating destination for adventurous travelers.</p>
</div>

<div className="ed-articles-card" >
  <img src={dubai} alt="articles"  className="ed-articles-card-pic"/>
  <h3>Dubai</h3>
<p>Dubai captivates visitors with its dazzling skyline, extravagant shopping, and a blend 
  of modern marvels and traditional charm, making it a premier tourist destination.</p>
</div>

<div className="ed-articles-card">
  <img src={malaysia} alt="articles"  className="ed-articles-card-pic" />
<h3>Malaysia</h3>
  <p>Malaysia offers a vibrant fusion of diverse cultures, pristine beaches, lush rainforests, and culinary delights, 
    making it an enchanting destination for travelers seeking adventure.</p>
</div>

<div className="ed-articles-card">
  <img src={tanzania} alt="articles"  className="ed-articles-card-pic" />
  <h3>Tanzania</h3>
  <p>
Tanzania captivates with its diverse landscapes, from the majestic Serengeti plains to the stunning beaches of Zanzibar,
 offering an unforgettable safari and cultural experience.</p>
</div>


</Caroussel>
    {/* <div className='immigration-cards'>

   <div class="immigration-card">
    <img src={kenya} alt="Avatar" style={{width:'100%'}}/>
    <div class="immigration-container">
    <h3>Kenya</h3> 
    <p style={{marginTop:'5%'}}>Kenya, known as the 'Jewel of East Africa,' captivates visitors with its unparalleled natural beauty and rich wildlife. 
    From the expansive savannahs of the Maasai Mara to the snow-capped peaks of Mount Kenya, the country offers a breathtaking array of landscapes. Embark on thrilling safaris to witness the 'Big Five' in their natural habitat, encounter vibrant tribal cultures, and relax on 
    pristine beaches along the Indian Ocean coast. Experience the rhythm of Nairobi, delve into the history of Lamu's ancient Swahili towns, or trek through the lush forests of the Aberdare Mountains. </p> 
     </div>  
    </div>


    <div class="immigration-card">
    <img src={canada} alt="Avatar" style={{width:'100%'}}/>
    <div class="immigration-container">
    <h3>Canada</h3> 
    <p  style={{marginTop:'5%'}}>Embark on an unforgettable journey to Canada, a land of vast wilderness, rugged coastlines, and vibrant cities. From the towering peaks of the Rocky
         Mountains to the pristine lakes of Banff National Park, Canada boasts some of the world's
         most awe-inspiring natural wonders. Explore the charming streets of Quebec City, experience the cosmopolitan vibe of Toronto, or marvel at the awe-inspiring Niagara Falls.</p> 
     </div>  
    </div>

    <div class="immigration-card">
    <img src={europe} alt="Avatar" style={{width:'100%'}}/>
    <div class="immigration-container">
    <h3>Europe</h3> 
    <p  style={{marginTop:'5%'}}>Europe is known for its rich history, diverse cultures, stunning architecture, and picturesque landscapes. 
        From the iconic landmarks of Paris and Rome to the charming villages of the Swiss Alps and the vibrant nightlife of Berlin and Barcelona, 
        Europe offers a plethora of experiences for travelers. Whether you're exploring the ancient ruins of Athens, cruising along the scenic
         fjords of Norway, or indulging in culinary delights in Italy, Europe captivates visitors with its blend of tradition and modernity, making it a timeless destination for travelers from around the world.</p> 
     </div>  
    </div>

    </div> */}



    <div className="zq-articles-card-sect">
        <h2 style={{marginBottom:'1rem'}} >Client Testimonials</h2>
        <Caroussel responsive={responsive} >

          <div className="zq-articles-card">
            <img src={susan} alt="articles"  className="zq-articles-card-pic" />
            <span>Zaituni - kilifi</span>
            <p>Edgeway Travel made our safari experience unforgettable! From the moment we arrived, everything was taken care of seamlessly. 
                The guides were knowledgeable, the accommodations were fantastic, and the wildlife sightings were incredible. Thank you, Edgeway Travel, for an amazing adventure</p>
          </div>

          <div className="zq-articles-card" >
            <img src={wanyama} alt="articles"  className="zq-articles-card-pic"/>
            <span>Wanyama - Mombasa </span>
            <p>I cannot recommend Edgeway Travel enough! They helped me plan a customized trip that perfectly suited my interests and budget. The team was attentive, responsive,
                 and went above and beyond to ensure I had an incredible experience exploring Kenya's diverse landscapes and vibrant culture.</p>
          </div>

          <div className="zq-articles-card">
            <img src={zeitun} alt="articles"  className="zq-articles-card-pic" />
            <span>Susan - Kisumu</span>
            <p>My family and I had the most wonderful time with Edgeway Travel on our recent vacation to Kenya. From the breathtaking views of Mount Kenya to the stunning beaches of Diani,
                 every moment was filled with awe and wonder. Thank you to the entire team for making our trip truly memorable</p>
          </div>

          <div className="zq-articles-card">
            <img src={lady} alt="articles"  className="zq-articles-card-pic" />
            <span>Nosim - Samburu</span>
            <p>Edgeway Travel exceeded all my expectations! From the moment I inquired about their services to the end of my trip, their team was professional, friendly, and attentive to my needs.
                 They helped me discover hidden gems in Kenya that I never would 
                 have found on my own. I can't wait to book my next adventure with Edgeway Travel.</p>
          </div>


        </Caroussel>
      </div>
            <Footer/>
        </div>

    )
}

export default Immigration