import React, { useState } from 'react';
import './header.css';
import logo from '../../assets/img/edgeway-logo.webp';
import { NavLink, Link } from "react-router-dom";
import {GiHamburgerMenu} from 'react-icons/gi'

function Header() {

  const [isNavExpanded, setIsNavExpanded] = useState(false)

  const handleTabClick = () => {
    setIsNavExpanded(false);
  };

  return (
    <nav className="navigation">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="Ziqweb logo" style={{width:'180px', height:'auto',marginTop:'1rem'}}/>

        </Link>
      </div>

      <button className="hamburger" onClick={() => {setIsNavExpanded(!isNavExpanded);}}>
        <GiHamburgerMenu fontSize="25px"/>
      </button>

      <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
        <ul className='topList'>
          {/* <li className='topListItem'>
            <NavLink to="/"
              className="topListItemLink"
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "#7C1034  " : "",
                }}}
              onClick={() => handleTabClick()}>
              Home
            </NavLink>
          </li> */}

          {/* <li className='topListItem'>
            <NavLink to="/about"
              className="topListItemLink"
              style={({isActive}) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "#7C1034  " : "",
                }}}
              onClick={() => handleTabClick()}>
              About
            </NavLink>
          </li> */}
          <li className='topListItem'>
            <NavLink to="/destination"
              className="topListItemLink"
              style={({isActive}) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "#7C1034  " : "",
                }}}
              onClick={() => handleTabClick()}>
              Destination
            </NavLink>
          </li>
          <li className='topListItem'>
            <NavLink to="/experientialtravel"
              className="topListItemLink"
              style={({isActive}) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "#7C1034  " : "",
                }}}
              onClick={() => handleTabClick()}>
              Experiential Travel
            </NavLink>
          </li>
          <li className='topListItem'>
            <NavLink to="/immigration"
              className="topListItemLink"
              style={({isActive}) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "#7C1034  " : "",
                }}}
              onClick={() => handleTabClick()}>
             Immigration Advisory
            </NavLink>
          </li>
          {/* <li className='topListItem'>
            <NavLink to="/specialvisaprogram"
              className="topListItemLink"
              style={({isActive}) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "#7C1034  " : "",
                }}}
              onClick={() => handleTabClick()}>
              Special Visa Program
            </NavLink>
          </li> */}

          <li className='topListItem'>
            <NavLink to="/news"
              className="topListItemLink"
              style={({isActive}) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "#7C1034  " : "",
                }}}
              onClick={() => handleTabClick()}>
              News
            </NavLink>
          </li>
          <li className='topListItem'>
          <NavLink to="/stories"
              className="topListItemLink"
              style={({isActive}) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "#7C1034  " : "",
                }}}
              onClick={() => handleTabClick()}>
              Stories
            </NavLink>
          </li>



          {/* <li className='topListItem'>
            <NavLink to="/login"
              className="topListItemLink"
              style={({isActive}) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "#7C1034  " : "",
                }}}
              onClick={() => handleTabClick()}>
              Login
            </NavLink>
          </li> */}
        </ul>
      </div>
    </nav>
  );
}

export default Header;