import React from 'react';
import { Link } from "react-router-dom";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import volcano from '../../assets/img/Volcano-banner.gif'

function Volcano() {

  window.scrollTo(0, 0);

  return (
    <div className="tsavo">
      <Header></Header>

      <div className='destination-banner'> 
        <img src={volcano} alt="Destination"/>

       </div>


       <div className='tsavo-text'>
        <h2 style={{fontWeight:'700'}}>VOLCANOES NATIONAL PARK</h2>
        <p style={{marginTop:'3rem'}}>Nestled within the lush greenery of the Virunga Mountains in Rwanda, Volcanoes National Park stands as a testament to the breathtaking beauty and incredible biodiversity of the African continent. This iconic park is renowned worldwide for
         its majestic mountain gorillas, captivating landscapes, and rich cultural heritage.</p>
         
     <h4 style={{marginTop:'3rem'}}>Experience the Majesty of Mountain Gorillas</h4>
     <p style={{marginTop:'1rem'}}>One of the most compelling reasons to visit Volcanoes National Park is for the rare and awe-inspiring opportunity to encounter mountain gorillas in their natural habitat. With dedicated gorilla trekking tours led by experienced guides, visitors have the chance to embark on an
      unforgettable journey through dense forests and misty slopes to observe these magnificent creatures up close.</p>

    <h4 style={{marginTop:'3rem'}}>Immerse Yourself in Nature's Splendor</h4>
    <p style={{marginTop:'1rem'}}> Beyond its renowned inhabitants, Volcanoes National Park offers a wealth of natural wonders waiting to be explored. From verdant bamboo forests to cascading waterfalls and volcanic peaks shrouded in mist, every corner of the park boasts unparalleled beauty and tranquility. Hiking trails wind through the park's diverse ecosystems, offering adventurers the
     chance to witness rare wildlife, vibrant bird species, and breathtaking vistas along the way.</p>

    <h4 style={{marginTop:'3rem'}}>Discover a Rich Cultural Heritage</h4>
    <p style={{marginTop:'1rem'}}>In addition to its natural wonders, Volcanoes National Park holds deep cultural significance as the ancestral homeland of the indigenous Batwa and Banyarwanda people. Visitors can immerse themselves in the local culture through guided village tours, traditional dance performances, and interactive experiences
     that showcase the customs, traditions, and daily life of these resilient communities.</p>



    </div>

      <Footer></Footer>
    </div>
  );
}

export default Volcano;