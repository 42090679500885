import React from 'react';
import { Link } from "react-router-dom";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

function Contribute() {

    window.scrollTo(0, 0);

  return (
    <div className="tsavo">
      <Header></Header>




       <div className='tsavo-text'>
        <h2 style={{fontWeight:'700'}}>Write for Us at Edgeway Travel</h2>
        <p style={{marginTop:'3rem'}}>Thank you for considering contributing to Edgeway Travel! We welcome insightful and engaging content from passionate travel enthusiasts, writers, and experts. Whether you're a seasoned traveler, a budding writer, or someone with a unique perspective on the world, 
        we invite you to share your experiences, tips, and stories with our community.  </p>
     <h4 style={{marginTop:'3rem'}}>Why Write for Us?</h4>

     <ul style={{marginTop:'2%'}}>
  <li>
    <strong>Reach a Global Audience:</strong> Edgeway Travel attracts travelers from around the world who are seeking inspiration, guidance, and firsthand insights into destinations and travel experiences.
  </li>
  <li>
    <strong>Showcase Your Expertise:</strong> Share your expertise, whether it's about a specific destination, travel tips, cultural insights, sustainable travel practices, or unique experiences.
  </li>
  <li>
    <strong>Build Your Portfolio:</strong> Writing for Edgeway Travel provides an excellent opportunity to showcase your writing skills and expand your portfolio with published work in a reputable travel publication.
  </li>
  <li>
    <strong>Connect with Like-Minded Individuals:</strong> Join our community of travel enthusiasts, bloggers, writers, and experts who share a passion for exploration and discovery.
  </li>
</ul>


    <h4 style={{marginTop:'3rem'}}>What We are Looking For</h4>

    <ul style={{ listStyleType: 'none', paddingLeft: '1rem' , marginTop:'1%'}}>
  <li>
    <span style={{ fontWeight: 'bold' }}>Originality:</span> We value original content that hasn't been previously published elsewhere.
  </li>
  <li>
    <span style={{ fontWeight: 'bold' }}>Relevance:</span> Content should be relevant to travel, including destination guides, travel tips, personal experiences, cultural insights, adventure stories, and more.
  </li>
  <li>
    <span style={{ fontWeight: 'bold' }}>Quality:</span> We strive for high-quality, well-written content that engages and informs our audience.
  </li>
  <li>
    <span style={{ fontWeight: 'bold' }}>Visuals:</span> If applicable, include high-resolution images to complement your written content.
  </li>
</ul>

    <h4 style={{marginTop:'3rem'}}>Submission guidelines</h4>

    <ul style={{ listStyleType: 'none', paddingLeft: '1rem' , marginTop:'1%'}}>
  <li>
    <span style={{ fontWeight: 'bold' }}>Originality:</span> We value original content that hasn't been previously published elsewhere.
  </li>
  <li>
    <span style={{ fontWeight: 'bold' }}>Relevance:</span> Content should be relevant to travel, including destination guides, travel tips, personal experiences, cultural insights, adventure stories, and more.
  </li>
  <li>
    <span style={{ fontWeight: 'bold' }}>Quality:</span> We strive for high-quality, well-written content that engages and informs our audience.
  </li>
  <li>
    <span style={{ fontWeight: 'bold' }}>Visuals:</span> If applicable, include high-resolution images to complement your written content.
  </li>
  <li>
    <span style={{ fontWeight: 'bold' }}>Word Count:</span> Articles typically range from 800 to 1500 words, but we welcome longer pieces for in-depth exploration.
  </li>
  <li>
    <span style={{ fontWeight: 'bold' }}>Formatting:</span> Please submit your articles in a Word document or Google Doc, with clear headings, subheadings, and any relevant links.
  </li>
  <li>
    <span style={{ fontWeight: 'bold' }}>Author Bio:</span> Include a brief author bio (approximately 50 words) along with your submission, including any relevant links to your personal website or social media profiles.
  </li>
  <li>
    <span style={{ fontWeight: 'bold' }}>Editing:</span> Our editorial team may make minor edits for clarity, grammar, and style, but we'll work closely with you to ensure your voice and message are preserved.
  </li>
</ul>

<p>
      <span style={{ fontWeight: 'bold', marginTop:'1%' }}>Join Us on the Journey</span>
    </p>
    <p>
      At Edgeway Travel, we believe that travel is not just about the destinations we visit but also the stories we share and the connections we make along the way. Join us in inspiring others to explore the world with curiosity, respect, and a sense of adventure.
    </p>
    <p>
      Thank you for considering writing for Edgeway Travel. We look forward to reading your submissions!
    </p>
    <p style={{fontStyle:'oblique'}}>
      Happy travels,<br />
      Mr Wekesa Kevin<br />
      Editor-in-Chief, Edgeway Travel
    </p>


    </div>

      <Footer></Footer>
    </div>
  );
}

export default Contribute;