import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import './blogs.css'
import { Link } from "react-router-dom";
import { FaRegUser } from "react-icons/fa";

import { CiSearch } from "react-icons/ci";
import blog1 from '../../../assets/img/blog1.webp'
import blog2 from '../../../assets/img/blog2.webp'
import blog3 from '../../../assets/img/blog3.webp'
import blog4 from '../../../assets/img/blog4.webp'




function Blogs()    {

    window.scrollTo(0, 0);

    return (
      <div className="blogs">
          <Header></Header>

          <div className='blogs-title'>
          <h1 style={{marginTop:'5%'}}> Latest News</h1>
          <h2 style={{marginTop:'2%'}}>Updates</h2>
          <p style={{marginTop:'2%'}}>Our latest news, updates, and stories for you</p>
          </div>

          <div className="search-container">
      <form action="/blogs">
      <CiSearch /> 
      <input type="text" placeholder="Search for a blog ..." name="search" style={{marginLeft:'0.4rem'}}/>
      <button type="submit">   </button>
    </form>
  </div>
              <div className="blogsContainer">


                          <div className="blogMember">
                            <img src={blog4} alt="tsavo"/>
                            <div className='blog-user'>
                            <FaRegUser />
                            <div className='blog-usercontent'>
                                <h3 style={{color:'brown', fontStyle:'oblique'}}>Eli.Abdul</h3>
                                <p style={{marginTop:'0.1rem'}}>Tue Nov 20 2023</p>
                            </div>

                            </div>
                            <Link className="bottomListItemLink" to="/firstBlog"> 
                                <div className="blogMemberName">Embracing the Global Tapestry</div>
                            </Link>
                            <Link className="bottomListItemLink" to="/firstBlog">
                                 <div className="infoItem" > 

                                  In the heart of every traveler lies an insatiable wanderlust, a desire to explore the far reaches of our planet
                                  and immerse oneself in the rich tapestry of cultures, landscapes, and experiences it offers.... </div>
                            </Link>                        
                                </div>

                                <div className="blogMember">
                            <img src={blog2} alt="tsavo"/>
                            <div className='blog-user'>
                            <FaRegUser />
                            <div className='blog-usercontent'>
                                <h3 style={{color:'brown', fontStyle:'oblique'}}>Salama Ahmed </h3>
                                <p style={{marginTop:'0.1rem'}}>Frid Jan 20 2024</p>
                            </div>

                            </div>
                            <Link className="bottomListItemLink" to="/secondBlog"> 
                                <div className="blogMemberName">A Symbol of Time and Travel</div>
                            </Link>
                            <Link className="bottomListItemLink" to="/secondBlog">
                                 <div className="infoItem" > In the vast tapestry of human existence, time is the one
                                  constant thread that weaves through every moment, guiding our journey through life's adventures. Like the sands in an hourglass... </div>
                            </Link>                        
                                </div>

                                
                                <div className="blogMember" style={{marginTop:'2%'}}>
                            <img src={blog3} alt="tsavo"/>
                            <div className='blog-user'>
                            <FaRegUser />
                            <div className='blog-usercontent'>
                                <h3 style={{color:'brown', fontStyle:'oblique'}}>Lucy King'ang'i</h3>
                                <p style={{marginTop:'0.1rem'}}>Wed Feb 22 2024</p>
                            </div>

                            </div>
                            <Link className="bottomListItemLink" to="/thirdBlog"> 
                                <div className="blogMemberName">The Synergy Between Tourism and Business</div>
                            </Link>
                            <Link className="bottomListItemLink" to="/thirdBlog">
                                 <div className="infoItem" >In the bustling world of commerce, where bottom lines and profit margins dominate discussions,
                                  an often-overlooked yet powerful force lies in the seamless integration of tourism and business... </div>
                            </Link>                        
                                </div>

                                
                                <div className="blogMember" style={{marginTop:'2%'}}>
                            <img src={blog1} alt="tsavo"/>
                            <div className='blog-user'>
                            <FaRegUser />
                            <div className='blog-usercontent'>
                                <h3 style={{color:'brown', fontStyle:'oblique'}}>Charles Ombeere</h3>
                                <p style={{marginTop:'0.1rem'}}>Thur Feb 29 2024</p>
                            </div>

                            </div>
                            <Link className="bottomListItemLink" to="/fourthBlog"> 
                                <div className="blogMemberName">Power of Resilience</div>
                            </Link>
                            <Link className="bottomListItemLink" to="/fourthBlog">
                                 <div className="infoItem" >Embracing change is an essential aspect of personal growth and development. It requires us to relinquish our 
                                 attachment to the familiar and embrace the unknown with courage and resilience... </div>
                            </Link>                        
                                </div>

                                </div>


          <Footer></Footer>
      </div>
  );
}
  export default Blogs;