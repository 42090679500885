export const accordionData = [
    {
      title: 'Acceptance of Terms',
      content: `By accessing or using our website, you agree to be bound by these Terms of Use. If you do not 
      agree to all the terms and conditions of this agreement, you may not access the website or use any services.`
    },
    {
      title: ' Use of The Terms',
      content: `You agree to use the website only for lawful purposes and in a way that does not infringe on the rights of,
       restrict, or inhibit anyone else's use and enjoyment of the website.`
    },
    {
      title: 'Privacy Policy',
      content: `Your privacy is important to us. 
      Please review our Privacy Policy, which governs how we collect, use, and disclose your personal information.`
    },
    {
      title: 'Intellectual Property',
      content: `All content on this website, including text, graphics, logos, images, audio clips, and software, is the property of Edgeway Travel or its licensors and is protected by intellectual 
      property laws. You may not use, reproduce, distribute, or transmit any content without our prior written consent.`
    },
    {
      title: 'Links to Third-Party Websites',
      content: `Our website may contain links to third-party websites that are not owned or controlled by Edgeway Travel. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites. You acknowledge and agree that Edgeway Travel shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with 
      the use of or reliance on any such content, goods, or services available on or through any third-party websites.`
    },


  ];