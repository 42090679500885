import React, { useEffect } from 'react';
import './events.css';
import { eventsData } from '../../../components/constants/constants';
import { BiLink } from 'react-icons/bi';

const Events = () => {
  useEffect(() => {
    const eventBoxes = document.querySelectorAll('.event-box');

    function checkScroll() {
      const triggerBottom = window.innerHeight * 0.8; // Trigger animation when the element is 80% in view
      eventBoxes.forEach((box) => {
        const boxTop = box.getBoundingClientRect().top;
        if (boxTop < triggerBottom) {
          box.classList.add('show');
        }
      });
    }

    window.addEventListener('scroll', checkScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, []);

  return (
    <section className="events section" id="events">
      <div className="events-title-wrapper container">
        <h4 className="events-title">Edgeway Adventures</h4>
      </div>
      <div className="events-container container grid">
        {eventsData.map(({ id, image, title, desc, Register }) => {
          return (
            <div key={id} className="event-box">
              <img src={image} alt={title} className="event-img" />
              <div className="event-content">
                <h3 className="event-name">{title}</h3>
                <p className="event-desc">{desc}</p>
                {/* <div className="event-link-wrapper">
                  <a
                    href={Register}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="event-link event-link-one"
                  >
                    Register
                    <BiLink className="event-icon" />
                  </a>
                </div> */}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Events;
