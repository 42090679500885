import React from 'react';
import { Link } from "react-router-dom";
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import maasaimara from '../../../assets/img/maasaimaraa.webp'

function Maasaimara() {

  window.scrollTo(0, 0);

  return (
    <div className="tsavo">
      <Header></Header>

      <div className='destination-banner'> 
        <img src={maasaimara} alt="Destination"/>

       </div>


       <div className='tsavo-text'>
        <h2 style={{fontWeight:'700'}}>MAASAI MARA NATIONAL PARK</h2>
        <h2 style={{fontWeight:'500', marginTop:'1rem'}}>Discover the untamed beauty of maasai lands</h2>

        <p style={{marginTop:'2rem'}}>Nestled in the heart of Kenya, Maasai Mara National Park stands as a testament to the raw, untamed beauty of Africa. Renowned worldwide for its extraordinary wildlife and stunning landscapes,
         Maasai Mara offers an unforgettable safari experience like no other. </p>
         
         <h2 style={{fontWeight:'500', marginTop:'2rem'}}>Wildlife spectacle</h2>
       <h4 style={{marginTop:'1rem'}}>Encounter the Big Five and Beyond</h4>
     <p style={{marginTop:'1rem'}}>Maasai Mara is home to an astonishing array of wildlife, making it a paradise for nature enthusiasts and photographers alike. Embark on thrilling game drives across the savannah plains and witness the iconic Big Five – lions, elephants, buffaloes, leopards, and rhinoceros – in their natural habitat. Marvel at the sight of vast herds of wildebeest, zebras, and antelopes as they migrate 
     across the Mara River, a spectacle that has been described as one of the greatest wildlife shows on Earth.</p>

     <h2 style={{fontWeight:'500', marginTop:'2rem'}}>Cultural richness</h2>
       <h4 style={{marginTop:'1rem'}}>Immerse yourself in Maasai culture</h4>
     <p style={{marginTop:'1rem'}}>Beyond its natural wonders, Maasai Mara offers a unique cultural experience with its vibrant Maasai community. Engage with the local Maasai people and learn about their rich traditions, customs, and way of life. Experience traditional Maasai dances, visit authentic villages, and gain insights into age-old practices such as beadwork, spear throwing, and cattle herding.
      Discover the deep connection between the Maasai people and the land they have called home for centuries.</p>

      <h2 style={{fontWeight:'500', marginTop:'2rem'}}>Safari adventures</h2>
       <h4 style={{marginTop:'1rem'}}>Explore the wilderness</h4>
     <p style={{marginTop:'1rem'}}>Embark on exhilarating safari adventures and explore the vast expanse of Maasai Mara. Whether by jeep, hot air balloon, or on foot, there are endless opportunities to uncover the park's hidden treasures. From guided bush walks to birdwatching safaris, 
     every moment spent in Maasai Mara promises excitement and discovery.</p>




    </div>

      <Footer></Footer>
    </div>
  );
}

export default Maasaimara;