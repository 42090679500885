import React from 'react';
import { Link } from "react-router-dom";
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import dianibeach from '../../../assets/img/watamubeachh.webp'

function Diani() {

  window.scrollTo(0, 0);

  return (
    <div className="tsavo">
      <Header></Header>

      <div className='destination-banner'> 
        <img src={dianibeach} alt="Destination"/>

       </div>


       <div className='tsavo-text'>
        <h2 style={{fontWeight:'700'}}>DIANI BEACH</h2>
        <p style={{marginTop:'3rem'}}>located on the stunning coast of Kenya, is a premier tourist destination known for its pristine white sands, crystal-clear 
        turquoise waters, and lush green surroundings. Here's some content to highlight its attractions:</p>
     <h4 style={{marginTop:'3rem'}}>1. Breathtaking beaches;</h4>
     <p style={{marginTop:'1rem'}}> Diani Beach boasts some of the most beautiful beaches in Africa. With soft, powdery sand stretching for miles along the Indian Ocean coastline,
      it's the perfect spot for sunbathing, swimming, and water sports.</p>

    <h4 style={{marginTop:'3rem'}}>2. Marine life:</h4>
    <p style={{marginTop:'1rem'}}> Dive into the vibrant underwater world of Diani Beach. Snorkeling and diving enthusiasts will be amazed by the colorful coral reefs teeming with exotic
     fish, sea turtles, and other marine creatures.</p>
    <h4 style={{marginTop:'3rem'}}>3. Water Sports:</h4>
    <p style={{marginTop:'1rem'}}>Adventure seekers can indulge in a variety of water sports, including kite surfing, windsurfing, jet skiing, and paddleboarding. Diani's warm, clear waters and consistent ocean breezes make it an ideal destination for these activities. 
    </p>
    <h4 style={{marginTop:'3rem'}}>4. Shimba hills national reserve:</h4>

    <p style={{marginTop:'1rem'}}>Just a short drive from Diani Beach lies the Shimba Hills National Reserve, a pristine wilderness area renowned for its rich biodiversity. Visitors can embark on guided safari tours to spot elephants, giraffes, buffalo, and rare species like the endangered Sable antelope.
      </p>
      <h4 style={{marginTop:'3rem'}}>5. Colobus Conservation:</h4>

    <p>Nature lovers can visit the Colobus Conservation, dedicated to the protection of the endangered Angolan colobus monkeys. Explore the nature trail, learn about primate conservation efforts, and observe these graceful creatures in their natural habitat.
      </p>
      <h4 style={{marginTop:'3rem'}}>6. Sunsets:</h4>


      <p>Conclude your day with a romantic sunset cruise along the coast. Relax onboard a traditional dhow sailboat, sip cocktails, 
        and marvel at the kaleidoscopic hues of the sunset painting the sky as the sun dips below the horizon.</p>



    </div>
      <Footer></Footer>
    </div>
  );
}

export default Diani;