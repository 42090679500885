
import React from "react";
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';

import Masonry from "react-responsive-masonry";
import group1 from '../../assets/group/gallery-01.webp';
import group2 from '../../assets/group/gallery-02.webp';
import group3 from '../../assets/group/gallery-03.webp';
import group4 from '../../assets/group/gallery-04.webp';
import group5 from '../../assets/group/gallery-05.webp';
import group6 from '../../assets/group/gallery-06.webp';
import group7 from '../../assets/group/gallery-07.webp';
import group8 from '../../assets/group/gallery-04.webp';
import group9 from '../../assets/img/ambience.webp';
import group10 from '../../assets/img/Volcano.gif';
import group11 from '../../assets/img/bwindi.webp';
import group12 from '../../assets/img/ngoro.avif';
import group13 from '../../assets/img/muturakach.webp';
import group14 from '../../assets/img/tourism.webp';
import group15 from '../../assets/img/watamu.webp';
import group16 from '../../assets/img/watamubeach.webp';
import group17 from '../../assets/img/nyungwe.webp';
import group18 from '../../assets/img/tsavo.webp';
import group19 from '../../assets/img/maasaimara.webp';
import group20 from '../../assets/img/kili.webp';



const images = [


    
    group10,
    group11,
    group12,
    group13,
    group14,
    group15,
    group16,
    group17,
    group18,
    group19,
    group20,
    group1,
    group2,
    group3,
    group4,
    group5,
    group6,
    group7,
    group8,
    group9,
];

class Pictures extends React.Component {

    
    render() {

        window.scrollTo(0, 0);

        return (
            <div >
            <Header></Header>
    
            <Masonry columnsCount={2} gutter="10px" style={{ marginTop: '3%' }}>
                {images.map((image, i) => (
                    <div className="image-container" key={i}>
                        <img src={image} alt={`Image ${i}`} />
                        <div className="overlay">
                        </div>
                    </div>
                ))}
            </Masonry>
            <Footer></Footer>
        </div>
        );
    }
}

export default Pictures;

