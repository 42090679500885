import React, { useState } from 'react';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import './news.css'
import { Link } from "react-router-dom";
import beach from '../../assets/img/beachnews.webp'
import newsbanner from '../../assets/img/news-banner.jpg'
import tourism from '../../assets/img/tourism.webp'
import beachfun from '../../assets/img/beachfun.webp'
import watamubeach from '../../assets/img/watamubeach.webp'









const News = () => {


  return (
    
    <div className="tabContainer">
              <Header></Header>

        <div className='news-banner'> 
        <img src={newsbanner} alt="banner-image"/>
        <div class="news-centered">

            {/* <h1 style={{ fontSize:'3rem', lineHeight:'normal', color:'whitesmoke'}}>News;</h1> */}
            </div>





        </div>
        <div className="meetTeam" >
            News on travel
        </div>

        <div className='news-container' style={{marginTop:'5%'}}>

            <div className='news-container-div1'>
            <img src={watamubeach} alt="visa" />


            </div>

            <div className='news-container-div2' >
                <h1>Unveiling the Untouched Beach Paradises of East Africa</h1>
                {/* <h3>Luxury Island Honeymoon</h3> */}

                <p style={{marginTop:'3%'}}>Nestled along the eastern shores of Africa lie pristine beach paradises waiting to be discovered. While well-known destinations like Zanzibar and Mombasa attract their fair share of visitors, the East African coastline harbors hidden gems that promise a truly unforgettable experience. From secluded coves fringed by coconut palms to bustling fishing villages steeped in local culture, 
                    the beaches of East Africa offer a diverse tapestry of landscapes and experiences. </p>


            </div>


        </div>

                <div className='news-container'>



            <div className='news-container-div3' >
                <h1>Luxury Island Honeymoon in East Africa</h1>

                <h3 style={{marginTop:'2%'}}>Luxury Island Honeymoon</h3>
                <p style={{marginTop:'3%'}}>Embark on a journey of romance and luxury as you celebrate your love amidst the breathtaking beauty of East Africa's secluded island paradises. Picture-perfect beaches, azure waters, and lavish accommodations await couples seeking an unforgettable honeymoon experience. Discover the exclusive allure of Mnemba Island off the coast of
                 Zanzibar, where intimate beachfront villas offer unparalleled privacy and personalized service.  </p>


            </div>
            <div className='news-container-div1'>
            <img src={beach} alt="visa" />


            </div>


        </div>

        <div className='news-container'>

            <div className='news-container-div1'>
            <img src={beachfun} alt="visa" />


            </div>

            <div className='news-container-div2'>
                <h1>East african's coastal charms</h1>

                {/* <h3>Luxury Island Honeymoon</h3> */}
                <p style={{marginTop:'3%'}}>East Africa's coastline is a tapestry of captivating beauty, where the rhythm of the ocean beckons travelers to embrace the laid-back allure of beach life. From the azure waters of the Indian Ocean to the powdery white sands that stretch as far as the eye can see, the beaches of East Africa offer a sanctuary for relaxation and rejuvenation. Explore the vibrant beach culture of destinations like Diani Beach in Kenya, where beach bars pulsate
                     with live music and colorful beach shacks serve up fresh seafood delicacies. </p>


            </div>


        </div>

        <div className='news-container' style={{marginTop:'5%'}}>


<div className='news-container-div3' >
    <h1>Where the Savannah Meets Serenity</h1>
    {/* <h3>Luxury Island Honeymoon</h3> */}

    <p style={{marginTop:'3%'}}>Renowned as one of the world's most iconic safari destinations, Maasai Mara offers an immersive experience in nature unlike any other. Home to the famed Great Migration, where millions of wildebeest, zebras, and gazelles traverse the plains in search of greener pastures, this vast expanse of wilderness teems with life year-round. But Maasai Mara is not just about the wildlife; it's also a place where visitors can connect with the rich culture and 
        traditions of the Maasai people, who have coexisted harmoniously with the land for centuries.  </p>


</div>

<div className='news-container-div1'>
<img src={tourism} alt="visa" />


</div>


</div>

        




<Footer></Footer>

    </div>
  );
};

export default News;

