import React, { useState } from 'react';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import './joinus.css';
import { Link } from "react-router-dom";
import contact from '../../../assets/img/2769512.jpg'



const JoinEdgewayCommunityForm = () => {

    window.scrollTo(0, 0);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');


  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can handle form submission, e.g., sending data to a server
    console.log('Name:', name);
    console.log('Email:', email);
    console.log('Message:', message);

    // You can also reset the form fields after submission
    setName('');
    setEmail('');
    setMessage('');

  };

  return (
    <div className="joinus-container">
      <Header />

      <div className='form-content'>
        <h2>Ready to become part of the Edgeway Travel community?
             Join us in our journey of exploration and discovery by filling out the form below.We can't wait to connect with you!</h2>
      </div>


      <div className="form-container">
            <div className='form-pic'>

            <img src={contact} alt="Destination"/>

            </div>
            <div className='actual-form'>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name *</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email *</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <input
              type="text"
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>

          <button type="submit" className="btn-join-community">Join Edgeway Community</button>
        </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default JoinEdgewayCommunityForm;
