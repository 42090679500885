import React from 'react';
import './privacypolicy.css';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';



const PrivacyPolicy = () => {
  // scroll to top
  window.scrollTo(0, 0);

  return (
    <div className="zq-privacy-policy-container">
      <Header />
      <header className="zq-pp-header">
        <h1>Privacy Policy for Edgeway Travel</h1>
      </header>
      <main className="zq-pp-main">
        <p className="zq-pp-main-intro">
          Thank you for choosing Edgeway Travel for your website needs. We are committed to protecting your privacy and ensuring the 
          security of your personal information. This Privacy Policy outlines our practices regarding the collection, use, 
          and protection of your data when you visit our website or engage our services. By using our website or 
          availing our services, you consent to the practices described in this Privacy Policy.
        </p>

        <div className="zq-pp-content">
          <h2>Information We Collect</h2>
          <ul className='zq-pp-details-item'>
            <li>
              <strong>Personal Information:</strong> When you engage with us or use our services, we may 
              collect personal information such as your name, email address, phone number, and other contact details as 
              necessary for providing our services.
            </li>
            <li>
              <strong>Usage Information:</strong> We may collect information about your interactions with our website, 
              including your IP address, browser type, pages visited, and the time and date of your visits. We may also 
              use cookies or similar technologies to enhance your browsing experience.
            </li>
          </ul>
        </div>

        <div className="zq-pp-content">
          <h2>How We Use Your Information</h2>
          <p>
            We use the information we collect for the following purposes:
          </p>
          <ul className='zq-pp-details-item'>
            <li>
              <strong>Providing Services:</strong> We use your personal information to provide our services to you.
            </li>
            <li>
              <strong>Communication:</strong> We may use your contact information to communicate with you regarding our 
              services, updates, and inquiries related to your projects.
            </li>
            <li>
              <strong>Improving Services:</strong> We analyze usage data to improve our website and services, ensuring 
              they meet your needs and expectations.
            </li>
          </ul>
        </div>

        <div className="zq-pp-content">
          <h2>Data Security</h2>
          <p>
            We take data security seriously and employ industry-standard security measures to protect your personal 
            information from unauthorized access, disclosure, alteration, and destruction. However, no data transmission 
            over the internet or electronic storage is entirely secure, and we cannot guarantee the absolute security of
             your information.
          </p>
        </div>

        <div className="zq-pp-content">
          <h2>Third-Party Services</h2>
          <p>
            We may use third-party services or tools to improve our website and services.
             These third-party services may collect and process data independently. We are not
             responsible for the privacy practices of these third parties, and you should review their privacy policies separately.
          </p>
        </div>

        <div className="zq-pp-content">
          <h2>Your Choices</h2>
          <p>
            You have the right to:
          </p>
          <ul className='zq-pp-details-item'>
            <li>
              <strong>Access and Update:</strong> Review, update, or request deletion of you
              r personal information by contacting us.
            </li>
            <li>
              <strong>Marketing Communications:</strong> Opt-out of marketing communications by following the 
              unsubscribe instructions provided in our emails.
            </li>
          </ul>
        </div>

        <div className="zq-pp-content">
          <h2>Children's Privacy</h2>
          <p>
            Our services are not intended for children under the age of 18. We do not knowingly collect personal information from children under 13. If you believe that we have collected personal information from a child under 13, please contact us immediately, and we will take steps to delete such information.
          </p>
        </div>

        <div className="zq-pp-content">
          <h2>Changes to this Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time to reflect changes in our practices or for legal reasons.
             We will notify you of any significant changes by posting the updated policy on our website.
          </p>
        </div>
      </main>
{/* 
      <footer>
        <p>If you have any questions or concerns about our Privacy Policy or how we handle your data, please contact us at:</p>
        <p>+254769212123</p>
        <p>Top Plaza 5th Floor room 4</p>
        <p>Kindaruma road off Ngong Road</p>
        <p>By using our services, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy. 
            Thank you for choosing Us.</p>
      </footer> */}


      <Footer />
    </div>
  );
};
<footer />

export default PrivacyPolicy;
