import React from 'react';
import { Link } from "react-router-dom";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import murchisson from '../../assets/img/murchisonn.webp'

function Murchisson() {

  window.scrollTo(0, 0);

  return (
    <div className="tsavo">
      <Header></Header>

      <div className='destination-banner'> 
        <img src={murchisson} alt="Destination"/>

       </div>


       <div className='tsavo-text'>
        <h2 style={{fontWeight:'700'}}>MURCHISSON FALLS</h2>
        <p style={{marginTop:'3rem'}}>Murchison Falls National Park, located in northern Uganda, is a captivating destination renowned for its breathtaking landscapes, diverse wildlife, and the majestic Nile River.  </p>
        <p>At the heart of this natural wonder lies the iconic Murchison Falls, where the Nile River plunges through a narrow gorge, creating a spectacular cascade and a thunderous roar that echoes through the surrounding wilderness.</p>
     <h4 style={{marginTop:'3rem'}}>1. Murchisson falls;</h4>
     <p style={{marginTop:'1rem'}}>Witness the awe-inspiring sight of the Nile River squeezing through a 7-meter wide gorge before plunging 43 meters into the frothing waters below. Feel the mist on your face and
      hear the rumble of the falls as you stand on the observation deck, soaking in the unparalleled beauty of nature's power.</p>

    <h4 style={{marginTop:'3rem'}}>2. Safari Adventures:</h4>
    <p style={{marginTop:'1rem'}}> Embark on an unforgettable safari experience through the vast savannah plains and lush forests of Murchison Falls National Park. Encounter the Big Five – lions, elephants, buffaloes, leopards, and 
    rhinos – along with a myriad of other wildlife, including giraffes, antelopes, hippos, and crocodiles.</p>

    <h4 style={{marginTop:'3rem'}}>3. Boat cruises:</h4>
    <p style={{marginTop:'1rem'}}>Glide along the tranquil waters of the Nile River on a scenic boat cruise. Marvel at the diverse birdlife that inhabits the riverbanks, including the majestic African fish eagle, colorful kingfishers, and graceful herons. 
    Keep your eyes peeled for basking crocodiles and bathing hippos along the shoreline.</p>
    <p style={{marginTop:'2rem'}}>Whether you're a nature enthusiast, adventure seeker, or simply seeking tranquility amidst pristine wilderness, Murchison Falls National Park promises an unforgettable journey into the heart of Africa's natural wonders. Come discover the splendor of Murchison Falls and create memories that will last a lifetime. </p>



    </div>

      <Footer></Footer>
    </div>
  );
}

export default Murchisson;