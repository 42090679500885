import React from 'react';
import { Link } from "react-router-dom";
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import blog1 from '../../../assets/img/blog4.webp'
import './blogs.css'
import { FaRegUser } from "react-icons/fa";


function firstBlog() {
  return (
    <div className="tsavo">
      <Header></Header>

      <div className='blogs-banner'> 
        <img src={blog1} alt="Destination"/>
        <div className='blog-user' style={{padding:'0% 20% 0% 20%'}}>
                            <FaRegUser />
                            <div className='blog-usercontent' >
                                <h3 style={{color:'brown', fontStyle:'oblique'}}>Eli.Abdul</h3>
                                <p style={{marginTop:'0.1rem'}}>Tue Nov 20 2023</p>
                            </div>

                            </div>

       </div>


       <div className='blog-text'>
        <h2 style={{fontWeight:'700', marginTop:'2%'}}>Embracing the Global Tapestry</h2>
        <p style={{marginTop:'1.5rem'}}>In the heart of every traveler lies an insatiable wanderlust, a desire to explore the far reaches of our planet and immerse oneself in the rich tapestry of cultures, landscapes, and experiences it offers. Traveling across continents is not merely a physical journey but 
        a soul-stirring odyssey—an opportunity to break free from the confines of familiarity and venture into the unknown.   </p>
        <p>It is a chance to witness the awe-inspiring majesty of nature, from the towering peaks of the Andes to the sprawling deserts of Africa, and to marvel at
           the ingenuity of human creativity, from the ancient wonders of Rome to the modern marvels of Tokyo.</p>
     <p style={{marginTop:'1rem'}}>With each new destination, we are confronted with the unfamiliar—the sights, sounds, and smells that awaken our senses and broaden our horizons. We roam bustling markets, sampling exotic cuisines and haggling with vendors whose languages we may not speak, yet with whom we share moments of laughter and connection. We navigate labyrinthine streets, guided by the whispers
      of history that echo through cobblestone alleys and ornate palaces, reminding us of the legacies of empires long past.</p>

    <p style={{marginTop:'1rem'}}> But perhaps the true beauty of traveling across continents lies not in the monuments we visit or the landscapes we behold, but in the people we meet along the way. In crowded train stations and remote villages, we encounter fellow travelers whose paths intersect with our own, each bearing their own stories, dreams, and perspectives. Through shared experiences and chance encounters, we forge bonds 
    that transcend borders and language barriers, united by a common humanity and a shared sense of wonder.</p>

    <p style={{marginTop:'1rem'}}>As we journey from one corner of the globe to another, we are reminded time and again of the remarkable diversity of our world—a diversity that enriches our lives, broadens our minds, and deepens our appreciation for the beauty of our planet and the people who inhabit it. And though our travels may take us to the farthest reaches of the earth, they 
    ultimately lead us back to ourselves, forever changed by the experiences we've had and the memories we've made along the way.</p>





    </div>

      <Footer></Footer>
    </div>
  );
}

export default firstBlog;