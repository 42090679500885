import React from 'react';
import { Link } from "react-router-dom";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import kilimanjaro from '../../assets/img/kilimanjaro.webp'

function Kilimanjaro() {

  window.scrollTo(0, 0);

  return (
    <div className="tsavo">
      <Header></Header>

      <div className='destination-banner'> 
        <img src={kilimanjaro} alt="Destination"/>

       </div>


       <div className='tsavo-text'>
        <h2 style={{fontWeight:'700'}}>MT. KILIMANJARO, The root of Africa</h2>
        <p style={{marginTop:'3rem'}}>Embark on an unforgettable journey to one of the most iconic natural wonders of the world - Mount Kilimanjaro. Towering majestically over the East African landscape, Kilimanjaro stands as Africa's tallest peak, 
        offering adventurers a once-in-a-lifetime experience of awe-inspiring beauty and exhilarating challenges. </p>

     <h4 style={{marginTop:'3rem'}}>The Adventure of a Lifetime</h4>
     <p style={{marginTop:'1rem'}}>Mount Kilimanjaro presents a thrilling challenge for both seasoned climbers and adventurous spirits alike. With its snow-capped summit gleaming against the Tanzanian sky, the mountain beckons 
     those who seek to conquer its heights and witness the breathtaking panoramas that await at every turn.</p>

    <h4 style={{marginTop:'3rem'}}>A diversity of landscapes</h4>
    <p style={{marginTop:'1rem'}}> As you ascend Kilimanjaro's slopes, you'll journey through a diverse array of ecosystems, from lush rainforests teeming with wildlife to otherworldly alpine deserts. Each ecological zone offers its own unique sights and
     experiences, making the climb an unforgettable exploration of nature's wonders.</p>

     <h4 style={{marginTop:'3rem'}}>The Kilimanjaro Routes</h4>
    <p style={{marginTop:'1rem'}}> Choose from a variety of routes to suit your preferences and experience level. Whether you opt for the popular Marangu Route, known as the "Coca-Cola Route," or the more challenging Machame Route, each path presents its own set of challenges and rewards.
     Whichever route you choose, our experienced guides will ensure your safety and comfort every step of the way.</p>

     <h4 style={{marginTop:'3rem'}}>Sunrise at Uhuru Peak</h4>
    <p style={{marginTop:'1rem'}}> The ultimate reward for your climb awaits at the summit - Uhuru Peak. As the first light of dawn bathes the landscape in a golden glow, you'll witness a sunrise like no other, with the vast African plains stretching out below you.
     It's a moment of triumph and reflection that will stay with you long after you've descended from the summit.</p>


    </div>

      <Footer></Footer>
    </div>
  );
}

export default Kilimanjaro;