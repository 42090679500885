import React, { useState } from 'react';
import './team_display.css'
import { Link } from "react-router-dom";

import {FaCode} from "react-icons/fa";
import {IoMegaphoneSharp} from "react-icons/io5";
import {BsCalendarCheck} from "react-icons/bs";
import {GiPencilBrush} from "react-icons/gi";
import destination from '../../../assets/img/destination.jpg'
import diani from '../../../assets/img/diani.webp'
import tsavo from '../../../assets/img/tsavo.webp'
import watamu from '../../../assets/img/watamu.webp'
import maasaimara from '../../../assets/img/maasaimara.webp'
import murchison from '../../../assets/img/murchison.webp'
import bwindi from '../../../assets/img/bwindi.webp'
import nyungwe from '../../../assets/img/nyungwe.webp'
import Volcano from '../../../assets/img/Volcano.gif'
import kilimanjaro from '../../../assets/img/kili.webp'
import serengeti from '../../../assets/img/serengeti.webp'
import ngoro from '../../../assets/img/ngoro.avif'







const Team = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="tabContainer">
        <div className='destination-banner'> 
        <img src={destination} alt="Destination"/>
        <div class="centered">

            <h1 style={{ fontSize:'3rem', lineHeight:'normal', color:'whitesmoke'}}>Destinations;</h1>
            </div>





        </div>
        <div className="meetTeam">
            Explore Edgeway tours & travel
        </div>

        <div className="teamSelectionBar">
            <button className={`tabButton ${activeTab === 'tab1' ? 'active' : ''}`} onClick={() => handleTabClick('tab1')} data-tab="tab1">
                <FaCode className='tabButtonIcon'/>
                <span className="tabButtonText">Kenya</span>
            </button>
            <button className={`tabButton ${activeTab === 'tab2' ? 'active' : ''}`} onClick={() => handleTabClick('tab2')} data-tab="tab2">
                <IoMegaphoneSharp className='tabButtonIcon'/>
                <span className="tabButtonText">Uganda</span>
            </button>
            <button className={`tabButton ${activeTab === 'tab3' ? 'active' : ''}`} onClick={() => handleTabClick('tab3')} data-tab="tab3">
                <BsCalendarCheck className='tabButtonIcon'/>
                <span className="tabButtonText">Rwanda</span>
            </button>
            <button className={`tabButton ${activeTab === 'tab4' ? 'active' : ''}`} onClick={() => handleTabClick('tab4')} data-tab="tab4">
                <GiPencilBrush className='tabButtonIcon'/>
                <span className="tabButtonText">Tanzania</span>
            </button>
        </div>

        <div className="teamPage">
            <div className={`tabContent ${activeTab === 'tab1' ? 'active' : ''}`} id="tab1">
                <div className="tabContentContainer">
                    <div className="teamMemberContainer">
                        <div className="teamMember">
                            <img src={tsavo} alt="tsavo"/>
                            <Link className="bottomListItemLink" to="/tsavo"> 
                                <div className="teamMemberName">Tsavo National park</div>
                            </Link>
                            <Link className="bottomListItemLink" to="/tsavo">
                                 <div className="infoItem" style={{lineHeight:'normal', borderRadius:'10%', padding:'1rem'}}> Tsavo is a breathtaking expanse of wilderness that embodies the essence of Africa's untamed beauty. Spanning over 20,000 square kilometers, it is one of the largest and oldest national parks in Kenya, renowned for its diverse ecosystems and abundant wildlife. </div>
                            </Link>                        
                                </div>
                        <div className="teamMember">
                            <img src={diani} alt="Diani"/>
                            <Link className="bottomListItemLink" to="/watamu"> 
                                <div className="teamMemberName">Watamu</div>
                            </Link>
                            <Link className="bottomListItemLink" to="/watamu">
                                 <div className="infoItem" style={{lineHeight:'normal', borderRadius:'10%', padding:'1rem'}}> Watamu Island, nestled along the coast of Kenya, is a hidden gem renowned for its pristine beaches,
                                 crystal-clear turquoise waters, and vibrant marine life. </div>
                            </Link>                         </div>
                        <div className="teamMember" style={{marginTop:'5%'}}>
                            <img src={watamu} alt="Hung Ngo"/>
                            <Link className="bottomListItemLink" to="/diani"> 
                                <div className="teamMemberName">Diani beach</div>
                            </Link>                           
                             <Link className="bottomListItemLink" to="/diani">
                                 <div className="infoItem" style={{lineHeight:'normal', borderRadius:'10%', padding:'1rem'}}> This coastal paradise offers visitors an idyllic retreat where they can immerse themselves in the beauty of
                                  nature while enjoying a range of activities, from snorkeling and scuba diving in the coral reefs to exploring the lush mangrove forests.</div>
                            </Link>                         </div>
                        <div className="teamMember" style={{marginTop:'5%'}}>
                            <img src={maasaimara} alt="Minh Phoung"/>
                            <Link className="bottomListItemLink" to="/maasaimara"> 
                                <div className="teamMemberName">Maasai Mara National ark</div>
                            </Link>                           
                             <Link className="bottomListItemLink" to="/maasaimara">
                                 <div className="infoItem" style={{lineHeight:'normal', borderRadius:'10%', padding:'1rem'}}> It is one of Africa's most renowned safari destinations, offering an unparalleled wildlife experience. This vast savannah ecosystem is home to an incredible 
                                 diversity of animals, including the Big Five (lion, elephant, buffalo, leopard, and rhinoceros), as well as wildebeests, zebras, giraffes, and more. </div>
                            </Link>                         </div>
                    </div>
                </div>
            </div>
            <div className={`tabContent ${activeTab === 'tab2' ? 'active' : ''}`} id="tab2">
                <div className="tabContentContainer">
                    <div className="teamMemberContainer">
                        <div className="teamMember">
                            <img src={murchison} alt="no.1"/>
                            <Link className="bottomListItemLink" to="/murchisson"> 
                                <div className="teamMemberName">Murchisson Falls</div>
                            </Link>                            
                            <Link className="bottomListItemLink" to="/murchisson">
                                 <div className="infoItem" style={{lineHeight:'normal', borderRadius:'10%', padding:'1rem'}}> 
                                 Murchison Falls National Park, nestled in Uganda, is a breathtaking haven for nature enthusiasts and adventure seekers alike. The park boasts the majestic Murchison Falls, 
                                 where the Nile River crashes through a narrow gorge, offering visitors a mesmerizing sight and unforgettable experiences amidst its diverse wildlife and stunning landscapes. </div>
                            </Link>                        </div>
                        <div className="teamMember">
                            <img src={bwindi} alt="no. 2"/>
                            <Link className="bottomListItemLink" to="/bwindi"> 
                                <div className="teamMemberName">Bwindi National park</div>
                            </Link>                            
                            <Link className="bottomListItemLink" to="/bwindi">
                                 <div className="infoItem" style={{lineHeight:'normal', borderRadius:'10%', padding:'1rem'}}> 

                                 Bwindi Impenetrable National Park in southwestern Uganda offers an unforgettable wildlife experience centered around gorilla trekking, allowing visitors to encounter 
                                 endangered mountain gorillas in their natural habitat. Additionally, the park boasts diverse bird species, lush flora, and other primates such as chimpanzees.  </div>
                            </Link>                         </div>
                    </div>
                </div>
            </div>
            <div className={`tabContent ${activeTab === 'tab3' ? 'active' : ''}`} id="tab3">
                <div className="tabContentContainer">
                    <div className="teamMemberContainer">
                    <div className="teamMember">
                            <img src={Volcano} alt="no. 3"/>
                            <Link className="bottomListItemLink" to="/volcano"> 
                                <div className="teamMemberName">Volcanoes National park</div>
                            </Link>                           
                             <Link className="bottomListItemLink" to="/volcano">
                                 <div className="infoItem" style={{lineHeight:'normal', borderRadius:'10%', padding:'1rem'}}> 
                                 Volcanoes National Park in Rwanda is a captivating destination renowned for its majestic mountain gorillas, offering visitors a unique opportunity to observe these endangered primates in their natural habitat. Beyond gorilla trekking, 
                                 the park's lush rainforests and volcanic terrain provide an enchanting backdrop for adventurous hiking experiences and immersive encounters with Rwanda's rich biodiversity. </div>
                            </Link>                        </div>
                        <div className="teamMember">
                            <img src={nyungwe} alt="no. 3"/>
                            <Link className="bottomListItemLink" to="/nyungwe"> 
                                <div className="teamMemberName">Nyungwe National park</div>
                            </Link>                            
                            <Link className="bottomListItemLink" to="/nyungwe">
                                 <div className="infoItem" style={{lineHeight:'normal', borderRadius:'10%', padding:'1rem'}}> 

                                 Nestled in the heart of Rwanda, Nyungwe National Park is a captivating destination renowned for its lush rainforests and diverse biodiversity. Visitors to Nyungwe are treated to immersive experiences such as thrilling canopy walks,
                                  vibrant birdwatching expeditions, and encounters with endangered primates like chimpanzees and colobus monkeys.</div>
                            </Link>                         </div>
                    </div>
                </div>
            </div>
            <div className={`tabContent ${activeTab === 'tab4' ? 'active' : ''}`} id="tab3">
                <div className="tabContentContainer">
                    <div className="teamMemberContainer">
                        <div className="teamMember">
                            <img src={serengeti} alt="no. 3"/>
                            <Link className="bottomListItemLink" to="/serengeti"> 
                                <div className="teamMemberName">Serengeti National park</div>
                            </Link>                            
                            <Link className="bottomListItemLink" to="/serengeti">
                                 <div className="infoItem" style={{lineHeight:'normal', borderRadius:'10%', padding:'1rem'}}> 

                                 Serengeti National Park, situated in Tanzania, is a world-famous destination renowned for its vast savannahs and iconic wildlife, including the majestic Big Five and the 
                                 spectacular Great Migration of wildebeests and zebras. Visitors to Serengeti National Park are treated to unforgettable safari experiences amidst breathtaking landscapes,
                                  offering a glimpse into the raw beauty and untamed wilderness of Africa.
                                 </div>
                            </Link>                        </div>
                        <div className="teamMember">
                            <img src={kilimanjaro} alt="no. 3"/>
                            <Link className="bottomListItemLink" to="/kilimanjaro"> 
                                <div className="teamMemberName">Mt Kilimanjaro</div>
                            </Link>                            
                            <Link className="bottomListItemLink" to="/kilimanjaro">
                                 <div className="infoItem" style={{lineHeight:'normal', borderRadius:'10%', padding:'1rem'}}> 

                                 Mount Kilimanjaro, Africa's highest peak, stands majestically in Tanzania, offering intrepid travelers an iconic trekking experience. Its snow-capped summit, shrouded in mystery and allure, 
                                 beckons adventurers from around the globe to conquer its heights and witness breathtaking vistas of the surrounding landscapes.
                                 </div>
                            </Link>                        </div>
                        <div className="teamMember" style={{marginTop:'5%'}} >
                            <img src={ngoro} alt="no. 3"/>
                            <Link className="bottomListItemLink" to="/ngorongoro"> 
                                <div className="teamMemberName">Ngorongoro Conservation Area</div>
                            </Link>                           
                             <Link className="bottomListItemLink" to="/ngorongoro">
                                 <div className="infoItem" style={{lineHeight:'normal', borderRadius:'10%', padding:'1rem'}}> 


                                 Ngorongoro Conservation Area, nestled in Tanzania, is a UNESCO World Heritage Site renowned for its breathtaking natural beauty and rich biodiversity.
                                  Home to the Ngorongoro Crater, the largest intact caldera in the world, this remarkable destination offers travelers the chance to witness 
                                 an incredible array of wildlife, including lions, elephants, and rhinos, all within the backdrop of stunning volcanic landscapes.
                                 </div>
                            </Link>                         </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
  );
};

export default Team;

