import React from 'react'
import './video.css'

function Videotext() {
    return (
        <div className='videobody'>
            <div className='videotext'>
                <div className='videotest'>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/BuurX3Q-U_4?si=bKEVDptSS8Hzij4b" title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
                </iframe></div>

                <div className='videotest' style={{marginLeft:'5%'}}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/RXSDK3FLMhI?si=Q-nnjUvp5pq55wwx" title="YouTube video player" frameborder="0"
                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
                
            </div>


            <div className='videotext'>
                <div className='videotest'>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/NnAP1obfiqU?si=MT0MWM1gmoqGDeJ3" title="YouTube video player" frameborder="0" allow="accelerometer;
                autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> </div>

                <div className='videotest' style={{marginLeft:'5%'}}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/ycS5hskzi5k?si=Ng3rYre8YsJ1NMGc" title="YouTube video player" frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                 allowfullscreen></iframe></div>
                
            </div>


          <div className='videotest'> </div>


            <div className='videotext'>
                <div className='videotest'>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/5aMgUQiPMCA?si=hU6xuMXViwe8gNXe" title="YouTube video player" frameborder="0"
                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                 allowfullscreen></iframe> </div>
                <div className='videotest' style={{marginLeft:'5%'}}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/BuurX3Q-U_4?si=bKEVDptSS8Hzij4b" title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
                </iframe></div>

            </div>


        </div>

    )
}

export default Videotext