import React from 'react';
import { Link } from "react-router-dom";
import "./tsavo.css"
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import tsavoo from '../../../assets/img/tsavoo.webp'

function Tsavo() {

  window.scrollTo(0, 0);

  return (
    <div className="tsavo">
      <Header></Header>

      <div className='destination-banner'> 
        <img src={tsavoo} alt="Destination"/>

       </div>


       <div className='tsavo-text'>
        <h2 style={{fontWeight:'700'}}>TSAVO NATIONAL PARK</h2>
        <p style={{marginTop:'3rem'}}>Tsavo National Park, a crown jewel of Kenya's wilderness, sprawls across an expansive landscape of over 20,000 square kilometers, making it one of the largest and oldest national parks in the country.  </p>
        <p>Split into two distinct sections, Tsavo East and Tsavo West, this iconic park offers a mesmerizing tapestry of ecosystems ranging from arid savannah plains to verdant riverine forests, rugged mountains, volcanic hills, and sprawling lava fields.</p>
     <h4 style={{marginTop:'3rem'}}>1. Biodiversity;</h4>
     <p style={{marginTop:'1rem'}}>Renowned for its remarkable biodiversity, Tsavo National Park provides sanctuary to a plethora of wildlife, including the famed "Big Five" - lions, elephants, buffaloes, leopards, and rhinoceroses - alongside a myriad of other fascinating species such as giraffes, zebras, hippos, crocodiles, and various antelope species. The park's vast expanses and diverse habitats 
     create an ideal environment for both common and rare species to thrive, offering visitors an unparalleled safari experience.</p>

    <h4 style={{marginTop:'3rem'}}>2. Activities:</h4>
    <p style={{marginTop:'1rem'}}> Exploring Tsavo National Park unveils a world of adventure and discovery. From exhilarating game drives through the endless plains to guided bush walks that reveal hidden wonders and birdwatching expeditions showcasing the park's avian diversity, there's no shortage of activities to immerse oneself in the natural splendor of Tsavo. Cultural interactions with local 
    communities add depth to the experience, offering insights into the rich heritage and traditions of the region.</p>
    <h4 style={{marginTop:'3rem'}}>3. Landmarks and natural wonders:</h4>
    <p style={{marginTop:'1rem'}}>Throughout Tsavo, iconic landmarks and natural wonders await exploration. The stunning lava flows of the Chyulu Hills, the thundering waters of the majestic Lugard Falls, and the mystical allure of the Mudanda Rock are just a few of the highlights that captivate visitors with their awe-inspiring beauty and geological significance.</p>
    <p style={{marginTop:'1rem'}}>Whether embarking on a thrilling safari adventure, seeking moments of tranquility amidst untamed landscapes, or simply marveling at the sheer magnificence of nature's creations, Tsavo National Park promises an unforgettable journey into the heart of wild Africa. </p>
    <p>With its untamed wilderness and breathtaking scenery, Tsavo beckons adventurers and nature enthusiasts to immerse themselves in an experience that transcends time and leaves an indelible mark on the soul.</p>



    </div>

      <Footer></Footer>
    </div>
  );
}

export default Tsavo;