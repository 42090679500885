import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import './map.css'
import loadGoogleMapsApi from 'load-google-maps-api';

import { Link } from "react-router-dom";
import React, { useEffect } from 'react';






function Map()    {

  window.scrollTo(0, 0);


    useEffect(() => {
        const initializeMap = async () => {
          try {
            const googleMaps = await loadGoogleMapsApi({ key: '' });
            const mapProp = {
              center: { lat: -1.2973611, lng: 36.7907341 },
              zoom: 17, // Adjust zoom level as needed
            };
            const map = new googleMaps.Map(document.getElementById("googleMap"), mapProp);
          } catch (error) {
            console.error('Error loading Google Maps API:', error);
          }
        };
    
        initializeMap();
      }, []);
    

    return (
        <div>
        <Header></Header>

        <div>
      <div id="googleMap" style={{ width: '100%', height: '500px' }}></div>
    </div>



          <Footer></Footer>
          </div>
  );
}
  export default Map;