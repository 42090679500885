import React from 'react';
import { Link } from "react-router-dom";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import bwindi from '../../assets/img/bwindii.webp'

function Bwindi() {

  window.scrollTo(0, 0);

  return (
    <div className="tsavo">
      <Header></Header>

      <div className='destination-banner'> 
        <img src={bwindi} alt="Destination"/>

       </div>


       <div className='tsavo-text'>
        <h2 style={{fontWeight:'700'}}>BWINDI NATIONAL PARK</h2>
        <p style={{marginTop:'3rem'}}>Nestled within the lush greenery of Uganda lies one of Africa's most captivating natural wonders – Bwindi Impenetrable National Park. This UNESCO World Heritage Site is renowned for its breathtaking biodiversity and, most notably, its population of majestic mountain gorillas.   </p>
        <p>Here, amidst the mist-covered slopes of the Rwenzori Mountains, adventurers and nature enthusiasts alike are invited to embark on an unforgettable journey into the heart of the African wilderness.</p>
     <h4 style={{marginTop:'3rem'}}>Discover the pristine wilderness</h4>
     <p style={{marginTop:'1rem'}}>Spanning over 321 square kilometers, Bwindi National Park is a haven for biodiversity, boasting dense tropical rainforests, cascading waterfalls, and a diverse array of flora and fauna. The park's name, "Impenetrable," aptly 
     describes the thick vegetation that blankets its landscape, providing a sanctuary for a myriad of wildlife species.</p>

    <h4 style={{marginTop:'3rem'}}> Encounter the mountain gorillas</h4>
    <p style={{marginTop:'1rem'}}> One of the most iconic inhabitants of Bwindi National Park is the critically endangered mountain gorilla. With nearly half of the world's remaining population calling this park home, Bwindi offers visitors a rare opportunity to witness these magnificent creatures up close in their natural habitat. Guided gorilla trekking expeditions lead adventurers through
     the dense forest, where every twist and turn unveils the wonders of these gentle giants and their fascinating social dynamics.</p>

    <h4 style={{marginTop:'3rem'}}>Experience authentic cultural encounters</h4>
    <p style={{marginTop:'1rem'}}>In addition to its rich natural beauty, Bwindi National Park is also steeped in cultural heritage. The park is inhabited by several local communities, including the Batwa pygmies, who have coexisted with the forest for generations. Visitors can engage in immersive cultural experiences, such as traditional performances, village walks, and craft demonstrations,
     gaining insight into the indigenous way of life and fostering meaningful connections with the local people.</p>

     <h4 style={{marginTop:'3rem'}}>Conservation and Sustainability</h4>
    <p style={{marginTop:'1rem'}}>Bwindi Impenetrable National Park stands as a testament to the importance of conservation efforts in preserving our planet's most precious ecosystems. Through sustainable tourism practices and community-based initiatives, the park strives to protect its biodiversity while supporting the livelihoods of local communities. By visiting Bwindi, travelers not only have the opportunity to witness unparalleled natural beauty but also contribute to the
     ongoing conservation efforts that ensure the survival of this extraordinary wilderness for generations to come.</p>



    </div>

      <Footer></Footer>
    </div>
  );
}

export default Bwindi;