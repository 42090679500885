import React, { useState } from 'react';
import './visa.css';
import { Link } from "react-router-dom";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { ImUsers } from "react-icons/im";
import { RiVisaFill } from "react-icons/ri";
import { IoDocuments } from "react-icons/io5";
import { AiFillSchedule } from "react-icons/ai";
import { RiUserShared2Line } from "react-icons/ri";
import { MdFlight } from "react-icons/md";
import visa from '../../assets/img/inquiry.jpg'








function Visa() {

    return (
        <div className='visa-page'>
            <Header></Header>

            <div className='visa-banner'>
                    <div className='visa-banner-text'>
                        <h1>Time to get <br></br>that visa?</h1>


                    </div>

                    <div className='visa-banner-pic'>
                    <img src={visa} alt="visa" />


                    </div>

                </div>

                <div className='visa-container'>
                    <div className='visa-container-content'>
                        <h1>How to get visa</h1>

                    </div>

                    <div className='finance-cards'>
                <div className='finance-containers'>
                  <h3 style={{width:'100%'}}>Integrity</h3>
                  <p style={{marginTop:'5%'}}> We operate with the highest ethical standards and integrity in all our interactions.</p>

                </div >
                <div  className='finance-containers'  >
                  <h3>Excellence</h3>
                  <p style={{marginTop:'5%'}}> We are committed to delivering excellence in every aspect of our consulting services.</p>

                </div>
                <div  className='finance-containers'  >
                 <h3>Innovation</h3>
                 <p style={{marginTop:'5%'}}>We embrace creativity and innovation to provide cuttingedge solutions.</p>

                </div>

                  <div  className='finance-containers'  >

                  <h3>Accountability</h3>
                  <p style={{marginTop:'5%'}}>We accept to be held accountable for the decisions we make.</p>

                </div>

              </div>


                    



                </div>

            <div className='visa-application'>




                <div className='visa-title'> 
                    <h1>How to get a visa;</h1>
                </div>
                <div className='visa-application-static'>
                    <div className='visa-application-icon'>
                    <ImUsers className='visa-application-icons'/>

                    </div>

                   <div className='visa-application-text'>
                   <h2>Book Consultation</h2>
                    <p>Talk to a counsellor about what you're looking for. They'll consider your academic history, your
                         goals, budget and your preferences, to suggest course and university options.</p>
                   </div>

                 </div>


                <div className='visa-application-flex'>


                   <div className='visa-application-text'>
                   <h2>Discuss Visa Requirements</h2>
                    <p>Our travel advisors will discuss the specific visa requirements for your destination country and the type of visa you need.
                         This may include information about visa categories, application forms, supporting documents, and processing times.</p>
                   </div>

                   <div className='visa-application-icon'>
                    <RiVisaFill className='visa-application-icons ' style={{marginLeft:'10%'}}/>

                    </div>
                </div>

                <div className='visa-application-static'>
                <div className='visa-application-icon'>
                    <IoDocuments className='visa-application-icons'/>

                    </div>

                   <div className='visa-application-text'>
                   <h2>Prepare Supporting Documents</h2>
                    <p>Based on the visa requirements discussed during your consultation, gather all necessary supporting documents, such as passport photos, proof of accommodation,
                         travel insurance, financial statements, and any other documents required.</p>
                   </div>
                 </div>


                <div className='visa-application-flex'>

                <div className='visa-application-text'>
                   <h2>Submit application documents</h2>
                    <p>Once you've gathered all required documents, our team will assist you in completing the visa application form accurately and thoroughly. We'll then submit your application to the relevant 
                        embassy or consulate on your behalf.</p>
                   </div>

                   <div className='visa-application-icon'>
                    <AiFillSchedule  className='visa-application-icons ' style={{marginLeft:'10%'}}/>

                    </div>
                </div>


                <div className='visa-application-static'>
                <div className='visa-application-icon'>
                    <RiUserShared2Line className='visa-application-icons'/>

                    </div>

                   <div className='visa-application-text'>
                   <h2>Attend visa interview</h2>
                    <p>In some cases, you may be required to attend a visa interview at the embassy or consulate.
                         Our travel advisors will provide guidance on preparing for the interview and accompany you if necessary.</p>
                   </div>
                 </div>


                <div className='visa-application-flex'> 
                   <div className='visa-application-text'>
                   <h2>We book your flight</h2>
                    <p>Talk to a counsellor about what you're looking for. They'll consider your academic history, your
                         goals, budget and your preferences, to suggest course and university options.</p>
                   </div>

                   <div className='visa-application-icon'>
                    < MdFlight className='visa-application-icons ' style={{marginLeft:'10%'}}/>

                    </div>
                </div>

                <hr class="new3"/>


            </div>



            <Footer></Footer>

        </div>
    )
}

export default Visa