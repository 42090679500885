import React from 'react';
import { Link } from "react-router-dom";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import serengeti from '../../assets/img/serengeti-banner.webp'

function Volcano() {

  window.scrollTo(0, 0);

  return (
    <div className="tsavo">
      <Header></Header>

      <div className='destination-banner'> 
        <img src={serengeti} alt="Destination"/>

       </div>


       <div className='tsavo-text'>
        <h2 style={{fontWeight:'700'}}>SERENGETI NATIONAL PARK</h2>
        <p style={{marginTop:'3rem'}}>Welcome to Serengeti National Park, an iconic sanctuary nestled in the heart of Tanzania, East Africa. Spanning over 14,750 square kilometers of sprawling savannahs, dense forests, and winding rivers,
         Serengeti offers an unparalleled adventure for nature enthusiasts and wildlife aficionados alike.  </p>

     <h4 style={{marginTop:'3rem'}}>A Wildlife Paradise</h4>
     <p style={{marginTop:'1rem'}}>Serengeti is renowned for its rich biodiversity and abundance of wildlife, making it a dream destination for safari enthusiasts. Home to the renowned "Big Five" – lions, elephants, buffaloes, leopards, and rhinoceros – as well as a myriad of other species, including cheetahs,
      giraffes, zebras, and wildebeests, the park promises unforgettable encounters with Africa's most iconic creatures.</p>

    <h4 style={{marginTop:'3rem'}}>The Great Migration</h4>
    <p style={{marginTop:'1rem'}}> Witness one of nature's most spectacular events, the Great Migration, where millions of wildebeests, zebras, and gazelles traverse the Serengeti plains in search of fresh grazing grounds. This awe-inspiring spectacle unfolds annually,
     offering visitors a front-row seat to the circle of life in the wild.</p>

    <h4 style={{marginTop:'3rem'}}>Safari Migration</h4>
    <p style={{marginTop:'1rem'}}>Embark on an exhilarating safari adventure and traverse the vast landscapes of Serengeti aboard 4x4 vehicles, guided by experienced rangers who unveil the secrets of the wilderness. Whether it's a thrilling game drive at dawn, a serene bushwalk to uncover hidden gems, or a mesmerizing 
    sunset safari against the backdrop of acacia-dotted horizons, Serengeti promises an adventure of a lifetime at every turn.</p>

    <h4 style={{marginTop:'3rem'}}>Cultural Encounter</h4>
    <p style={{marginTop:'1rem'}}>Beyond its natural wonders, Serengeti also offers enriching cultural experiences, allowing visitors to connect with indigenous tribes such as the Maasai people, who have coexisted with wildlife for generations. Immerse yourself in their vibrant traditions, from traditional
     dances to insightful storytelling sessions, and gain a deeper appreciation for the harmonious relationship between man and nature.</p>



    </div>

      <Footer></Footer>
    </div>
  );
}

export default Volcano;