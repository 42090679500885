import React from 'react'
import { Link } from 'react-router-dom';
import './intro.css';
import axios from 'axios';
import group1 from '../../../assets/img/hero-img01.webp';
import group2 from '../../../assets/img/hero-video.mp4';
import group3 from '../../../assets/img/hero-img02.webp';
import  pdf from '../../../assets/group/Edgeway Profile.pdf'


import { useState, useEffect } from 'react'

function getDate() {
    const today = new Date();
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const month = months[today.getMonth()];
    const year = today.getFullYear();
    const date = today.getDate();
    return `${month} ${date}, ${year}`;
  }

function Intro() {
    const [  , setIntro] = useState([]);

    useEffect(() => {
        const fetchDates = async () => {
            const res = await axios.get("/home/")
            setIntro(res.data[0])
        }
        fetchDates()
    }, [])

    const [currentDate,   ] = useState(getDate());


  return (
    <div className="intro">
        <div className="introLeft">
            <div className="date">
                <span>{currentDate}</span>
            </div>
            <div className="introTitle">
                <span>Discover your gateway to memorable experience</span>
            </div>
            <div className="introTextContent">
            At Edgeway Travel we´re not just travel consultants; we´re architects of extraordinary journeys. We believe true travel transcends sightseeing and
             souvenirs-it´s about forging deep connections with vibrant cultures, immersing yourself in breathtaking landscapes, and returning home with memories that
               resonate for a lifetime.
            </div>
            <div className="introBtn">
                {/* <Link to="/tickets" target='_blank'>
                    <button className='leftBtn'>GET TICKETS</button>
                </Link> */}
                <Link to={pdf} target='_blank'>
                    <button className='rightBtn'>VIEW EDGEWAY PROFILE</button>
                </Link>
            </div>
        </div>
        <div className="introRight">
            <img className='imgRight'  src={group1} alt="Home Page" />

        </div>
        <div className="introRight">
        <video className='imgRight' style={{marginTop:'4%'}} src={group2} alt="" loop autoPlay muted/>

        </div>
        <div className="introRight">
        <img className='imgRight' style={{marginTop:'12%'}} src={group3} alt="Home Page" />

        </div>
    </div>
  )
}

export default Intro