import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import Home from "./pages/home/home_page.jsx";
import './app.css';
import ReactGA from 'react-ga4';

import {
  BrowserRouter as Router,
  Route,
  BrowserRouter,
  Routes
} from "react-router-dom";
import About from "./pages/about/about_page.jsx";
import Watch from "./pages/watch/watch_page.jsx";
import Team from "./pages/team/team_page.jsx";
import Stories from "./pages/tickets/tickets.jsx";
import FAQs from "./pages/faqs/faqs.jsx";
import Login from "./pages/login/login_page.jsx";
import Immigration from "./pages/immigration/immigration.jsx"
import Visa from "./pages/visa/visa.jsx"
import News from "./pages/news/news.jsx"
import Tsavo from './pages/team/tsavo/tsavo.jsx'
import Watamu from './pages/team/watamu/watamu.jsx'
import Diani from './pages/team/diani/diani.jsx'
import Maasaimara from './pages/team/maasaimara/maasaimara.jsx'
import  Murchisson from './pages/team/murchisson.jsx'
import  Bwindi from './pages/team/bwindi.jsx'
import Volcano from './pages/team/volcano.jsx'
import Nyungwe from './pages/team/nyungwe.jsx'
import Serengeti from './pages/team/serengeti.jsx'
import Kilimanjaro from "./pages/team/kilimanjaro.jsx";
import Ngorongoro from "./pages/team/ngorongoro.jsx";
import Pictures from './pages/footer/pictures.jsx'
import Blogs from './pages/footer/blogs/blogs.jsx'
import FirstBlog from './pages/footer/blogs/blog1.jsx'
import SecondBlog from './pages/footer/blogs/blog2.jsx'
import ThirdBlog from './pages/footer/blogs/blog3.jsx'
import FourthBlog from './pages/footer/blogs/blog4.jsx'
import Map from './pages/footer/map/map.jsx'
import Policy from './pages/footer/privacypolicy/privacypolicy.jsx'
import Aboutus from './pages/aboutus/aboutus.jsx'
import Contactus from './pages/footer/contactus/contactus.jsx'
import Career from './pages/footer/career/career.jsx'
import Contribute from './pages/footer/contribute.jsx'
import Community from './pages/footer/joinus/joinus.jsx'
import { RiFacebookBoxFill } from 'react-icons/ri';




function App() {

  
  useEffect(() => {
    ReactGA.initialize('G-5KY4YDTVED');
    ReactGA.send({ 
      hitType: "pageview", 
      page: window.location.pathname + window.location.search, 
      title: "Home page"
     });
  }, []);
  
  return (


    <BrowserRouter>
          <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-5KY4YDTVED"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-5KY4YDTVED');
        `}
      </script>
    </Helmet>
      <Routes>



        <Route path="/" element={<Home/>}/>
        <Route path="/experientialtravel" element={<About/>}/>
        <Route path="/watch" element={<Watch/>}/>
        <Route path="/destination" element={<Team/>}/>
        <Route path="/specialvisaprogram" element={<Visa/>}/>
        <Route path="/news" element={<News/>}/>
        <Route path="/stories" element={<Stories/>}/>
        <Route path="/termsofuse" element={<FAQs/>}/>
        {/* <Route path="/login" element={<Login/>}/> */}
        
        <Route path="/immigration" element={<Immigration/>}/>
        <Route path="/tsavo" element={<Tsavo/>}/>
        <Route path="/watamu" element={<Watamu/>}/>
        <Route path="/diani" element={<Diani/>}/>
        <Route path="/maasaimara" element={<Maasaimara/>}/>
        <Route path="/murchisson" element={<Murchisson/>}/>
        <Route path="/bwindi" element={<Bwindi/>}/>
        <Route path="/volcano" element={<Volcano/>}/>
        <Route path="/nyungwe" element={<Nyungwe/>}/>
        <Route path="/serengeti" element={<Serengeti/>}/>
        <Route path="/kilimanjaro" element={<Kilimanjaro/>}/>
        <Route path="/ngorongoro" element={<Ngorongoro/>}/>
        <Route path="/pictures" element={<Pictures/>}/>
        <Route path="/blogs" element={<Blogs/>}/>
        <Route path="/firstblog" element={<FirstBlog/>}/>
        <Route path="/secondblog" element={<SecondBlog/>}/>
        <Route path="/thirdblog" element={<ThirdBlog/>}/>
        <Route path="/fourthblog" element={<FourthBlog/>}/>
        <Route path="/sitemap" element={<Map/>}/>
        <Route path="/privacypolicy" element={<Policy/>}/>
        <Route path="/aboutus" element={<Aboutus/>}/>
        <Route path="/contactus" element={<Contactus/>}/>
        <Route path="/career" element={<Career/>}/>
        <Route path="/contribute" element={<Contribute/>}/>
        <Route path="/community" element={<Community/>}/>


        



















      </Routes>
    </BrowserRouter>
  );
}

export default App;

