import React from 'react';
import './abt_body.css';
// import Videotext from "../../watch/video/video";
import food from '../../../assets/img/muturakach.webp';
import masai from '../../../assets/img/masai.webp';
import fort from '../../../assets/img/fort.webp';

import Event from '../../../assets/img/direction.webp';
import Event2 from '../../../assets/img/map.webp';
import Event1 from '../../../assets/img/ambience.webp';
import YourHeaderImage from '../../../assets/img/experiential.jpg';


// Define your content and images for each card here
const cardData = [
  {
    image: Event, // Replace with your image source
  },
  {
    title: ' Explore our curated selection of captivating destinations.',
    body: 'Discover the world through a different lens with Edgeway Travel. Our passion is crafting journeys that ignite your sense of wonder and leave you breathless with anticipation. Join us as we curate unforgettable experiences that redefine the way you see the world.',
    image: null, // Remove the image for Card 2
  },
  {
    image: Event1, // Replace with your image source
  },
  {
    title: ' Immerse yourself in unforgettable adventures.',
    body: 'Unlock the door to a world of endless possibilities and unparalleled adventures by your side. Our dedication to crafting unique and immersive experiences ensures that every step of your journey is filled with excitement and discovery.  ',
    image: null, // Remove the image for Card 4
  },
  {
    image: Event2, // Replace with your image source
  },
  {
    title: ' Solo adventures designed for independent explorers.',
    body: 'Rediscover the joy of exploration and the thrill of discovery because every destination has a story to tell. From the bustling streets of vibrant cities to the serene beauty of remote landscapes, our curated journeys uncover the hidden gems of the world.  ',
    image: null, // Remove the image for Card 6
  },
];

function About() {
    return (
      <div className="about-container">
        <div className="about-heading">
          <img src={YourHeaderImage} alt="Header" />
          {/* <div class="bottom-right">Bridging the gap of trust, <br></br>making travelling fun and easy </div> */}

          <h1 style={{marginTop:'1.5em'}}> <span style={{color:'#761034'}}>Off-the-Beaten-Path:</span> <span style={{fontStyle:'oblique'}}>Discover hidden gems and lesser-known destinations.</span></h1>

        </div>
        <div className="about">
          {cardData.map((card, index) => (
            <div className="card" key={index}>
              {card.image && <img src={card.image} alt={card.title} className="card-image" />}
              <div className="card-content">
                <h3 className="card-title">{card.title}</h3>
                <p className="card-body">{card.body}</p>
              </div>
            </div>
          ))}
        </div>
        <h1 style={{marginTop:'1.5em', textAlign:'center', lineHeight:'normal'}}> <span style={{color:'#761034'}}>Experiential Travel:</span> <span style={{fontStyle:'oblique'}}>Immerse yourself in a tapestry of traditions, flavors, and adventures.</span></h1>

        <div className='experiential-container'>
          <div className='experiential-container-text'>
            <h2 >Cultural Diversity in Kenya</h2>
            <p style={{marginTop:'1rem'}}>Explore the rich tapestry of Kenyan culture, where over 40 distinct ethnic groups coexist harmoniously. From the vibrant Maasai warriors adorned in their traditional attire to the rhythmic beats of coastal Swahili dances, Kenya's cultural landscape is as diverse as it is fascinating. Immerse yourself in age-old traditions,
               from the intricate beadwork of the Samburu to the colorful festivals celebrating harvests and rites of passage.</p>
          </div>
          <div className='experiential-container-pic'>
          <img src={masai} alt="Header" />

          </div>

        </div>
        <div className='experiential-container'>
        <div className='experiential-container-pic'>
          <img src={food} alt="Header" />

          </div>
          <div className='experiential-container-text'>
            <h2>Explore the unique food industry in kenya</h2>
            <p style={{marginTop:'1rem'}}>Indulge your taste buds in Kenya's rich and diverse culinary scene, where traditional flavors blend with modern influences. From aromatic Swahili dishes along the coast to hearty stews and grilled meats in the savannah, experience the vibrant tapestry of Kenyan cuisine. Discover the unique spices,
             fresh produce, and cultural significance behind each dish, immersing yourself in a gastronomic adventure like no other..</p>
          </div>


        </div>
        <div className='experiential-container'>
          <div className='experiential-container-text'>
            <h2 >Historic Landmarks & Scenic Wonders</h2>
            <p style={{marginTop:'1rem'}}>Discover Kenya's rich history and breathtaking landscapes, from iconic landmarks like Fort Jesus to the natural wonders of the Great Rift Valley. Explore centuries-old forts, vibrant cities, and pristine beaches, immersing yourself in the beauty and culture of this diverse East African nation.</p>
          </div>
          <div className='experiential-container-pic'>
          <img src={fort} alt="Header" />

          </div>

        </div>
        {/* <Videotext/> */}
      </div>
    );
  }

export default About;