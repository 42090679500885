import React, { useEffect, useState } from 'react';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import './career.css';
import { Link } from 'react-router-dom';
import career from '../../../assets/img/career.png';
import Vector from '../../../assets/img/Vector.png';
import grad from '../../../assets/img/grad.png';
import icon from '../../../assets/img/icon.png';
import statistic from '../../../assets/img/statistic.png';
import logo from '../../../assets/img/edgeway-logo.webp';

const Career = () => {

    window.scrollTo(0, 0);

  const [activeTab, setActiveTab] = useState('Software');

  useEffect(() => {
    document.getElementById(activeTab).style.display = 'block';
  }, [activeTab]);

  const handleTabClick = (jobFaculty) => {
    const tabcontent = document.getElementsByClassName('tabcontent');
    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none';
    }
    setActiveTab(jobFaculty);
  };

  return (
    <div className="career-body">
      <Header />
      <div className="career-banner">
        <img src={career} alt="Destination" />
        <div className="career-centered">
          <h1 style={{ fontSize: '3rem', lineHeight: 'normal' }}>Join Us;</h1>
          <p>
          At Edgeway, we believe in unlocking the potential of every individual to shape unforgettable travel experiences for our global community. Whether you're an aspiring explorer or a seasoned professional, discover fulfilling career paths that align with your passion for travel and adventure.
           Explore our openings below and embark on a journey where every day is an opportunity to inspire, innovate, and explore the world.
          </p>
        </div>
      </div>

      <div className="career-content">
        <div className="career-content-content">
          <h3>BENEFITS</h3>
          <h1 style={{ marginTop: '5%' }}>Why you Should Join Our <br />Awesome Team</h1>
          <p style={{ marginTop: '5%' }}>We want to feel like home when you are working at
            Edgeway Travel & for that we have curated a great set of benefits for you.</p>
        </div>
        <div className="career-cards">
          <div className="career-card">
            <img src={Vector} alt="Career-card" />
            <h2 style={{ marginTop: '5%' }}>Team Work</h2>
            <p style={{ marginTop: '5%' }}>Foster a culture of collaboration by actively participating
            in team meetings, brainstorming sessions, and project retrospectives.</p>
          </div>
          <div className="career-card">
            <img src={grad} alt="Career-card" />
            <h2 style={{ marginTop: '5%' }}>Learning Opportunity</h2>
            <p style={{ marginTop: '5%' }}> Join our mentorship program where experienced team members can provide guidance and support to junior employees,
             fostering professional growth and skill development.</p>
          </div>
        </div>
        <div className="career-cards-section">
          <div className="career-card">
            <img src={icon} alt="Career-card" />
            <h2 style={{ marginTop: '5%' }}>Secure future</h2>
            <p style={{ marginTop: '5%' }}> Join career advancement within our organization, and opportunities 
            for professional development, promotions, and skill enhancement</p>
          </div>
          <div className="career-card">
            <img src={statistic} alt="Career-card" />
            <h2 style={{ marginTop: '5%' }}>Upgrade skills</h2>
            <p style={{ marginTop: '5%' }}>Join our mentorship program where experienced team members can provide guidance and support to junior employees,
             fostering professional growth and skill development.</p>
          </div>
        </div>
      </div>

      <div className="career-logo">
        <img src={logo} alt="Career-logo" />
      </div>

      <div className="career-choice">
        <div className="career-text" >
          <h2 style={{paddingTop:'5%'}}>Come Join Us</h2>
          <h1 style={{ marginTop: '1%' }}>Career Openings</h1>
          <p style={{ marginTop: '1%' }}>We’re always looking for creative, talented self-starters to join the Hemllin community Check out our open roles below and fill out an application.</p>
        </div>
      </div>

      <div className="tab">
        <div className='tab-button'>
        <button className={`tablinks ${activeTab === 'Software' ? 'active' : ''}`} onClick={() => handleTabClick('Software')}>
          SOFTWARE
        </button>
        <button className={`tablinks ${activeTab === 'Design' ? 'active' : ''}`} onClick={() => handleTabClick('Design')}>
          DESIGN
        </button>
        <button className={`tablinks ${activeTab === 'Marketing' ? 'active' : ''}`} onClick={() => handleTabClick('Marketing')}>
          MARKETING
        </button>
        
        </div>

        <div className='tab-flex'>
        <div id="Software" className="tabcontent">
            <div className='tab-content-flex'>
                <div>
                  <Link className="bottomListItemLink" to="/career">
                        <div className="infoItem" style={{color:'#761064'}}>Software Developer</div>
                    </Link>
                 </div>

          <div style={{marginLeft:'5%'}}>
          <h3>Experience</h3>
          <p style={{color:'grey'}}>2 years</p>
          </div>

          
          <div style={{marginLeft:'5%'}}>
          <h3>Deadline</h3>
          <p style={{color:'grey'}}>31/01/2023</p>
          </div>
          </div>
          

        </div>

        

        <div id="Design" className="tabcontent">
        <div className='tab-content-flex'>
                <div>
                  <Link className="bottomListItemLink" to="/career">
                        <div className="infoItem" style={{color:'#761064'}}>Graphic Designer</div>
                    </Link>
                 </div>

          <div style={{marginLeft:'5%'}}>
          <h3>Experience</h3>
          <p style={{color:'grey'}}>2 years</p>
          </div>

          
          <div style={{marginLeft:'5%'}}>
          <h3>Deadline</h3>
          <p style={{color:'grey'}}>31/01/2023</p>
          </div>
          </div>
        </div>

        <div id="Marketing" className="tabcontent">
        <div className='tab-content-flex'>
                <div>
                  <Link className="bottomListItemLink" to="/career">
                        <div className="infoItem" style={{color:'#761064'}}>Marketing Manager</div>
                    </Link>
                 </div>

          <div style={{marginLeft:'5%'}}>
          <h3>Experience</h3>
          <p style={{color:'grey'}}>2 years</p>
          </div>

          
          <div style={{marginLeft:'5%'}}>
          <h3>Deadline</h3>
          <p style={{color:'grey'}}>31/01/2023</p>
          </div>
          </div>
        </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Career;
