import React from 'react';
import { Link } from "react-router-dom";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import nyungwe from '../../assets/img/nyungwe-banner.webp'

function Volcano() {

  window.scrollTo(0, 0);

  return (
    <div className="tsavo">
      <Header></Header>

      <div className='destination-banner'> 
        <img src={nyungwe} alt="Destination"/>

       </div>


       <div className='tsavo-text'>
        <h2 style={{fontWeight:'700'}}>NYUNGWE NATIONAL PARK</h2>
        <p style={{marginTop:'3rem'}}>Welcome to Nyungwe National Park, a sanctuary of biodiversity nestled in the heart of Rwanda. 
        This sprawling expanse of verdant rainforest is a haven for nature lovers, adventurers, and seekers of tranquility alike. </p>
        <p>Spanning over 1,000 square kilometers, Nyungwe is one of Africa's oldest and most pristine mountain rainforests,
             teeming with life and adventure at every turn.</p>

     <h4 style={{marginTop:'3rem'}}>Explore the Wilderness</h4>
     <p style={{marginTop:'1rem'}}> <span style={{fontWeight:'bolder', fontStyle:'oblique'}}>Canopy Walkway:</span> Embark on a thrilling adventure as you traverse the treetops along East Africa's only canopy walkway. Suspended high above the forest floor, this aerial bridge offers breathtaking views of the lush canopy below, 
     with opportunities to spot rare bird species and playful primates swinging through the branches.</p>

     <p style={{marginTop:'1rem'}}> <span style={{fontWeight:'bolder', fontStyle:'oblique'}}>Chimpanzee Trekking:</span>  Delve into the heart of the forest on an unforgettable chimpanzee trekking expedition. Nyungwe is home to one of East Africa's largest populations of chimpanzees, offering visitors a chance to observe these fascinating 
     creatures in their natural habitat, accompanied by experienced guides who provide insights into their behavior and conservation efforts.</p>

     <p style={{marginTop:'1rem'}}> <span style={{fontWeight:'bolder', fontStyle:'oblique'}}>Waterfall Hikes:</span> Immerse yourself in the tranquil beauty of Nyungwe as you embark on a journey to discover its hidden waterfalls. Follow winding trails through the forest, listening to the soothing sounds
      of cascading water as you encounter picturesque falls nestled amidst the lush greenery.</p>

     <p style={{marginTop:'1rem'}}> <span style={{fontWeight:'bolder', fontStyle:'oblique'}}>Birdwatching:</span>  With over 300 bird species, including 27 endemics, Nyungwe is a paradise for birdwatchers. Whether you're an avid birder or a casual enthusiast, the park's diverse habitats—from montane forests to
      swamps—provide endless opportunities to spot colorful avian species, including the elusive Albertine Rift endemics.</p>

 



    </div>

      <Footer></Footer>
    </div>
  );
}

export default Volcano;