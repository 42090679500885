import React from 'react'
import { Link } from "react-router-dom";
import './footer.css'
import logo from '../../assets/img/edgeway-logo.webp'
// importing react icons library
import {FaFacebook} from 'react-icons/fa'
import {FaInstagram} from 'react-icons/fa'
import  {FaLinkedin} from 'react-icons/fa'
import {FaYoutube} from 'react-icons/fa'

function Footer() {
  return (
    <div className="bottom">
        <div className="bottomLogo">
            <Link to="/">
                <img src={logo} alt="ziqweb/hemllinlogo"/>
            </Link>
        </div>
        <div className="footerTitles">
            <span className='footerTitle'>Shop</span>
            <span className='footerTitle'>Resources</span>
            <span className='footerTitle'>World's best</span>
            <span className='footerTitle'>Explore</span>



        </div>
        <div className='footerBody'>
            <div className="information">
                <div className="infoItems">
                    <Link className="bottomListItemLink" to="/destination">
                        <div className="infoItem">Destination guides</div>
                    </Link>
                    <Link className="bottomListItemLink" to="/pictures">
                        <div className="infoItem">Apparel</div>
                    </Link>
                    <Link className="bottomListItemLink" to="/stories">
                        <div className="infoItem">Travel packages</div>
                    </Link>

                </div>
            </div>
            <div className="contact">
                <div className="infoItems">
                <Link className="bottomListItemLink" to="/pictures">
                        <div className="infoItem">Pictures</div>
                    </Link>
                    <Link className="bottomListItemLink" to="/watch">
                        <div className="infoItem">Videos</div>
                    </Link>
                    <Link className="bottomListItemLink" to="/blogs">
                        <div className="infoItem">Travel blogs</div>
                    </Link>

                </div>
            </div>
            <div className="contact">
                <div className="infoItems">
                        <div className="infoItem">Hotels</div>
                        <div className="infoItem">Cities</div>
                        <div className="infoItem">Airlines</div>
                </div>
            </div>
            <div className="socials">
            <div className="information">
                <div className="infoItems">
                    <Link className="bottomListItemLink" to="/">
                        <div className="infoItem">Travel guides</div>
                    </Link>
                    <Link className="bottomListItemLink" to="/team">
                        <div className="infoItem">Packages</div>
                    </Link>
                    <Link className="bottomListItemLink" to="/watch">
                        <div className="infoItem">Travel resources</div>
                    </Link>

                </div>
            </div>
            </div>

        </div>

        {/* <div>
            <h2 style={{color:'white'}}>About Us</h2>
            <Link className="bottomLink" to="/faqs">
                        <div className="infoItem">Frequently Asked Questions</div>
                    </Link>

        </div> */}
                <div className="footerTitles">
            <span className='footerTitle'>About Us</span>
            <span className='footerTitle'></span>
            <span className='footerTitle'>Travel Deals</span>
            <span className='footerTitle'>Socials</span>



        </div>


        <div className='footerBody'>
            <div className="information">
                <div className="infoItems">
                    <Link className="bottomListItemLink" to="/aboutus">
                        <div className="infoItem">About Edgeway travel</div>
                    </Link>
                    <Link className="bottomListItemLink" to="/contactus">
                        <div className="infoItem">Contact Us</div>
                    </Link>
                    <Link className="bottomListItemLink" to="/privacypolicy">
                        <div className="infoItem">Privacy Policy</div>
                    </Link>
                    <Link className="bottomListItemLink" to="/termsofuse">
                        <div className="infoItem">Terms & Conditions</div>
                    </Link>
                </div>
            </div>
            <div className="contact">
                <div className="infoItems">
                <Link className="bottomListItemLink" to="/career">
                        <div className="infoItem">Work for us</div>
                    </Link>
                <Link className="bottomListItemLink" to="/contribute">
                        <div className="infoItem">Write for us</div>
                    </Link>
                    <Link className="bottomListItemLink" to="/sitemap">
                        <div className="infoItem">Site map</div>
                    </Link>
                    <Link className="bottomListItemLink" to="/watch">
                        <div className="infoItem">Advertising</div>
                    </Link>
                    <Link className="bottomListItemLink" to="/community">
                        <div className="infoItem">Join Edgeway Travel community</div>
                    </Link>
                </div>
            </div>
            <div className="contact">
                <div className="infoItems">
                        <div className="infoItem">Hotel destinations</div>
                        <div className="infoItem">Exclusive packages</div>
                        <div className="infoItem">Seasonal promotions</div>
                </div>
            </div>

            <div className="socials">
                <div className="infoItemsIcons">
                    <Link className="infoListItemLink" to="https://www.facebook.com/edgewaytravel" target="_blank">
                        <div className="infoItemIcon">
                            <FaFacebook className="facebookIcon"/>
                        </div>
                    </Link>
                    <Link className="infoListItemLink" to="https://www.instagram.com/edgewaytravel/" target="_blank">
                        <div className="infoItemIcon">
                            <FaInstagram className="instagramIcon"/>
                        </div>
                    </Link>
                    <Link className="infoListItemLink" to="https://www.linkedin.com/showcase/edgeway-travel/" target="_blank">
                        <div className="infoItemIcon">
                            <FaLinkedin className="linkedinIcon"/>
                        </div>
                    </Link>
                    <Link className="infoListItemLink" to="https://www.youtube.com/channel/UC2IFCEiFw4Goft69XRP37lQ" target="_blank">
                        <div className="infoItemIcon">
                            <FaYoutube className="youtubeIcon"/>
                        </div>
                    </Link>
                </div>
            </div>

        </div>

        <div className="line">
            <hr></hr>
        </div>
        <div className="copyright">
            <p>© Copyright • Edgewaytravel 2024 • All Rights Reserved.</p>
        </div>
    </div>
  )
}

export default Footer