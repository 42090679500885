import React from 'react'
import './partners.css'
import { Link } from 'react-router-dom';
import partner1 from '../../../assets/img/eventbrite.webp'
import partner2 from '../../../assets/img/ziqwebLogo.webp'
import partner3 from '../../../assets/img/hemllin.webp'
import partner4 from '../../../assets/img/ledrad.webp'
import partner5 from '../../../assets/img/Vertical Logo-01.webp'





function Partners() {
  return (
    <div className="partner">

        <div className="talkTitle">Our <span className='talks'>Partners</span></div>

        <div className="zq-partners">

          <img src="https://www.jetscoot.com/pub/static/frontend/Magento/luma/en_GB/Magento_Email/logo_email.png" alt="" />
          <img src={partner5}  alt="" />

          <img src={partner1} alt="" />
          {/* <img src={partner3} alt="" /> */}
          <img src={partner2}  alt="" />

          {/* <img src={partner4}  alt="" /> */}

        </div>

            {/* Add a link to the button */}
            {/* <div className="viewMoreButton">
            <Link to="/tickets" target='_blank'>
                    <button className='viewMoreTalks'>VIEW MORE EVENTS</button>
                </Link>
            </div> */}

    </div>

  )
}

export default Partners