import Event from'./events/events';
import './events/events.css';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

function EventPage() {

  window.scrollTo(0, 0);

  return (
    <div className="aboutpage">
      <Header></Header>
      <Event></Event>
      <Footer></Footer>
    </div>
  );
}

export default EventPage;