import React from 'react'
import { Link } from "react-router-dom";
import './platform.css';
import Platform1 from '../../../assets/group/IMG_5826.jpg'

function upcomingEvent() {
  return (
    <div className="conferenceVenue">
        <div className="backdrop">
            <img src={Platform1} alt="platform" />
            <span className="space"></span>
        </div>
        <div className="textRight">
            <div className="titleConference">Experience</div>
            <div className="textTitleLarge"><h3>Holiday Moments is customer-centric!<br></br>We are focused on delivering the best services to our clients in a timely, reliable and cost-effective way.</h3></div>
            <div className="textTitleLarge" ><h3>We offer services for individuals and leisure groups, as well as business and
             MICE travelers.</h3></div>
            <div className="textTitleLarge"><h3>Our goal is not just to send you on a trip, it´s to inspire you with transformative experiences that stay with you long after you return hoem.</h3></div>
            {/* <div className="textRightBtn">
                <Link to="https://www.google.com/maps/place/Hemllin/@-1.2973665,36.7907341,17z/data=!3m2!4b1!5s0x182f1098532f9993:0x9d8eae8e712ee9d6!4m6!3m5!1s0x182f1157f2d232fd:0x5e513248664a128b!8m2!3d-1.2973665!4d36.793309!16s%2Fg%2F11jbt_v12q?entry=ttu" target="_blank">
                  <button className='locationBtn'>VIEW LOCATION</button>
                </Link>
            </div> */}
        </div>
    </div>
  )
}

export default upcomingEvent