import React from 'react'
import './faqs.css'
import Accordion from "./accordion/accordion";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { accordionData } from './accordion/accordion_data';
import destination from '../../assets/img/background-photo.jpg'


function FAQs() {

  window.scrollTo(0, 0);

  return (
    <div className="FAQPage">
      <Header></Header>
      <div className='faq-banner'> 
        <img src={destination} alt="Destination"/>
        <div class="centered">

            <h1 style={{ fontSize:'3rem', lineHeight:'normal'}}>Our terms of use;</h1>
            </div>





        </div>
      <div className='title'>Welcome to Edgeway Travel! These Terms of Use govern your use of our website, products, and services.
       By accessing or using our website, you agree to comply with these terms. Please read them carefully.</div>
      <div className='accordion'>
        {accordionData.map(({ title, content }) => (
          <Accordion title={title} content={content}></Accordion>
        ))}
      </div>

      <div className='faq-footer'>
        <h3>These Terms of Use shall be governed by and construed in accordance with the laws of Kenya, 
          without regard to its conflict of law provisions.</h3>
          <h3>If you have any questions about these Terms of Use, please contact us at [info@edgewaytravel.com].</h3>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default FAQs;
