import React from 'react';
import { Link } from "react-router-dom";
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import watamu from '../../../assets/img/dianibanner.webp'


function Watamu() {

  window.scrollTo(0, 0);

  return (
    <div className="tsavo">
      <Header></Header>

      <div className='destination-banner'> 
        <img src={watamu} alt="Destination"/>

       </div>


       <div className='tsavo-text'>
        <h2 style={{fontWeight:'700'}}>Watamu: A Tropical Paradise</h2>
        <p style={{marginTop:'3rem'}}>Nestled along the picturesque coastline of Kenya, Watamu is a charming coastal village renowned for its pristine white sandy beaches, crystal-clear turquoise waters, and vibrant marine life.   </p>
        <p>This tropical paradise offers visitors an unforgettable blend of natural beauty, rich cultural heritage, and exciting adventure opportunities.</p>
     <h4 style={{marginTop:'3rem'}}>Beaches and Marine Life</h4>
     <p style={{marginTop:'1rem'}}>Watamu is home to some of the most stunning beaches in East Africa. Stretching for miles along the shoreline, the powdery soft sands invite visitors to relax under the warm African sun and enjoy breathtaking views 
     of the Indian Ocean. The calm and shallow waters are perfect for swimming, snorkeling, and diving.</p>
     <p style={{marginTop:'1rem'}}>One of the highlights of Watamu is the Watamu Marine National Park and Reserve, a protected area teeming with colorful coral reefs, exotic fish, and other marine creatures. Visitors can explore this underwater wonderland through guided snorkeling and diving tours, witnessing the mesmerizing beauty of 
     the coral gardens and encountering fascinating marine species like dolphins, turtles, and whale sharks.</p>

    <h4 style={{marginTop:'3rem'}}>Cultural Heritage</h4>
    <p style={{marginTop:'1rem'}}> Beyond its natural wonders, Watamu boasts a rich cultural heritage shaped by centuries of Swahili,
     Arab, and European influences. The village is dotted with historical landmarks, including ancient mosques, Swahili ruins,
      and colonial-era buildings, offering a glimpse into its diverse past.</p>
      <p style={{marginTop:'1rem'}}> Visitors can immerse themselves in the local culture by exploring traditional markets, sampling authentic Swahili cuisine, and interacting with friendly locals known for their warm hospitality. Cultural performances, 
      including traditional dances and music, provide insight into the vibrant traditions of the coastal communities.</p>
    <h4 style={{marginTop:'3rem'}}>Eco-Tourism and Adventure</h4>
    <p style={{marginTop:'1rem'}}>For eco-conscious travelers, Watamu offers a range of eco-tourism initiatives and sustainable activities aimed at preserving its natural environment. Guided nature walks through lush coastal forests, birdwatching expeditions in nearby wildlife sanctuaries, 
    and mangrove kayaking tours provide opportunities to connect with nature while supporting conservation efforts.</p>
    <p style={{marginTop:'1rem'}}>Thrill-seekers can embark on adrenaline-pumping adventures such as deep-sea fishing, kite surfing, and stand-up paddleboarding, taking advantage of the region's ideal wind and water conditions. Sunset dhow cruises along the coastline offer a romantic way to end the day,
     with stunning views of the horizon ablaze with vibrant colors.</p>



    </div>
      <Footer></Footer>
    </div>
  );
}

export default Watamu;