import React from 'react';
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import blog4 from '../../../assets/img/blog1.webp'
import { FaRegUser } from "react-icons/fa";


function fourthBlog() {
  return (
    <div className="tsavo">
      <Header></Header>

      <div className='blogs-banner'> 
        <img src={blog4} alt="Destination"/>
        <div className='blog-user' style={{padding:'0% 20% 0% 20%'}}>
                            <FaRegUser />
                            <div className='blog-usercontent' >
                                <h3 style={{color:'brown', fontStyle:'oblique'}}>Charles Ombeere</h3>
                                <p style={{marginTop:'0.1rem'}}>Thur Feb 29 2024</p>
                            </div>

                            </div>

       </div>


       <div className='blog-text'>
        <h2 style={{fontWeight:'700'}}>Power of resilience</h2>
        <p style={{marginTop:'1.5rem'}}>Embracing change is an essential aspect of personal growth and development. It requires us to relinquish our
         attachment to the familiar and embrace the unknown with courage and resilience.  </p>
        <p>Change often comes unbidden, disrupting the comfortable routines of our lives and challenging our sense of security. Yet, it is through
           embracing change that we discover new opportunities, unleash our creativity, and unlock our fullest potential.</p>

     <p style={{marginTop:'1rem'}}>At its core, embracing change is about cultivating a mindset of adaptability and openness. Instead of resisting change out of fear or uncertainty, we can choose to greet it as a catalyst for transformation and renewal. Just as a caterpillar undergoes metamorphosis to emerge as a butterfly,
      so too must we embrace change as a natural part of our journey towards self-realization.</p>

    <p style={{marginTop:'1rem'}}> In the face of change, we may experience discomfort and uncertainty as we navigate uncharted territories. However, it is precisely during these moments of transition that we have the opportunity to redefine ourselves and our aspirations. By letting go of outdated beliefs and embracing new possibilities, 
    we can chart a course towards a more fulfilling and authentic life.</p>

    <p style={{marginTop:'1rem'}}>Embracing change also requires us to cultivate resilience in the face of adversity. Like a tree bending with the wind, we must learn to adapt and bend without breaking, drawing strength from the challenges we encounter. Every obstacle becomes an opportunity for growth, and every setback becomes 
    a stepping stone towards greater resilience and self-discovery.</p>

    <p style={{marginTop:'1rem'}}>Ultimately, embracing change is a courageous act of self-affirmation. It is a declaration of our willingness to embrace the uncertainties of life and to trust in our ability to navigate them with grace and resilience. As we embrace change with an open heart and a fearless spirit, we unlock the door
     to infinite possibilities and embark on a journey of self-transformation and renewal.</p>



    </div>

      <Footer></Footer>
    </div>
  );
}

export default fourthBlog;