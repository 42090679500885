import React, { useState }  from 'react';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import './contactus.css'
import { Link } from "react-router-dom";
import {FaFacebook} from 'react-icons/fa'
import {FaInstagram} from 'react-icons/fa'
import  {FaLinkedin} from 'react-icons/fa'
import {FaYoutube} from 'react-icons/fa'
import contact from '../../../assets/img/2769512.jpg'



function contactUs()    {

    window.scrollTo(0, 0);


    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [message, setMessage] = useState('');
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Here you can handle form submission, e.g., sending data to a server
      console.log('Name:', name);
      console.log('Email:', email);
      console.log('Company:', company);
      console.log('Message:', message);
      // You can also reset the form fields after submission
      setName('');
      setEmail('');
      setCompany('');
      setMessage('');
    };

    

    return (
        <div className='contact-body'>
        <Header></Header>

        <div className='contact-title'>
            <h1>
                Contact Us
            </h1>

        </div>
        <div className='contact-subhead'>
        <h2 style={{color:'grey'}}>Connect with Us to Start Your Next Adventure! Whether you have questions about our services, need assistance with trip planning, or simply want to share your travel aspirations, 
                our team at Edgeway Travel is here to help. Reach out to us today and 
                let's begin crafting your perfect journey together!</h2>
        </div>

        <div className="form-container">
            <div className='form-pic'>

            <img src={contact} alt="Destination"/>

            </div>
            <div className='actual-form'>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name *</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email *</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="company">Company:</label>
            <input
              type="text"
              id="company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <input
              type="text"
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn-join-community">Submit</button>
        </form>
        </div>
      </div>

        <div className='contact-cards'>
                <div className='contact-containers'>
                  <h3 style={{width:'100%'}}> <span style={{color:'#761034'}}>Edgeway</span> Travel</h3>
                  <p style={{marginTop:'5%'}}> Your trusted companion in exploration. As a leading authority in the travel industry,
                   we pride ourselves on providing seamless booking experiences.</p>

                </div >
                {/* <div  className='contact-containers'  >
                  <h3>Address</h3>
                  <p style={{marginTop:'5%'}}> info@edgewaytravel.com</p>
                  <p>Nairobi, Kenya</p>

                </div>
                <div  className='contact-containers'  >
                 <h3> CONTACT INFO</h3>
                 <p style={{marginTop:'5%'}}>+254 758 784696</p>
                 <p>Email;</p>
                 <p>info@edgewaytravel.com</p>

                </div> */}
                <div  className='contact-containers'  >

                  <h3>Social links</h3>
                  <div className="infoItemsIcons">
                    <Link className="infoListItemLink" to="https://www.facebook.com/edgewaytravel" target="_blank">
                        <div className="infoItemIcon">
                            <FaFacebook className="facebookIcon"/>
                        </div>
                    </Link>
                    <Link className="infoListItemLink" to="https://www.instagram.com/edgewaytravel/" target="_blank">
                        <div className="infoItemIcon">
                            <FaInstagram className="instagramIcon"/>
                        </div>
                    </Link>
                    <Link className="infoListItemLink" to="https://www.linkedin.com/showcase/edgeway-travel/" target="_blank">
                        <div className="infoItemIcon">
                            <FaLinkedin className="linkedinIcon"/>
                        </div>
                    </Link>
                    <Link className="infoListItemLink" to="https://www.youtube.com/channel/UC2IFCEiFw4Goft69XRP37lQ" target="_blank">
                        <div className="infoItemIcon">
                            <FaYoutube className="youtubeIcon"/>
                        </div>
                    </Link>
                </div>

                </div>

              </div>




          <Footer></Footer>
          </div>
  );
}
  export default contactUs;