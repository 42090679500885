import React from 'react';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import ngorongoro from '../../assets/img/ngoro.avif'

function Ngorongoro() {

  window.scrollTo(0, 0);

  return (
    <div className="tsavo">
      <Header></Header>

      <div className='destination-banner'> 
        <img src={ngorongoro} alt="Destination"/>

       </div>


       <div className='tsavo-text'>
        <h2 style={{fontWeight:'700'}}>NGORONGORO CONSERVATION AREA</h2>
        <p style={{marginTop:'3rem'}}>Nestled in the heart of Tanzania, the Ngorongoro Conservation Area is a UNESCO World Heritage Site
         and one of Africa's most breathtaking natural wonders. This expansive area, spanning over 8,000 square kilometers, 
         is a testament to the diversity and splendor of East Africa's landscapes and wildlife.</p>

     <h4 style={{marginTop:'3rem'}}>A Geological Marvel</h4>
     <p style={{marginTop:'1rem'}}>At the center of the Ngorongoro Conservation Area lies the Ngorongoro Crater, an ancient volcanic caldera renowned for its dramatic scenery and unparalleled biodiversity. Created over two million years ago, the crater's towering walls shelter a pristine ecosystem teeming with life. Visitors are treated to panoramic
      views of grassy plains, acacia forests, and shimmering soda lakes, all cradled within the crater's imposing rim.</p>

    <h4 style={{marginTop:'3rem'}}>Wildlife Sanctuary</h4>
    <p style={{marginTop:'1rem'}}> The Ngorongoro Conservation Area is home to an astonishing array of wildlife, making it a haven for nature enthusiasts and photographers alike. Within the crater, visitors can encounter iconic African species such as lions, elephants, buffaloes, and rhinos, along with elusive predators like leopards and cheetahs. The crater's
     fertile plains also attract vast herds of wildebeests, zebras, and gazelles, creating unforgettable scenes of natural abundance.</p>

    <h4 style={{marginTop:'3rem'}}>Cultural Heritage</h4>
    <p style={{marginTop:'1rem'}}> Beyond its natural wonders, the Ngorongoro Conservation Area is rich in cultural significance. The Maasai people, semi-nomadic pastoralists with a deep connection to the land, have inhabited these plains for centuries. Their traditional bomas dot the landscape, offering visitors a glimpse into their way of life and traditions. Engage in
     cultural exchanges, learn about traditional Maasai customs, and witness captivating performances of song and dance.</p>

     <h4 style={{marginTop:'3rem'}}>Plan your Journey</h4>
    <p style={{marginTop:'1rem'}}> Whether you're a seasoned traveler or embarking on your first African safari, a visit to the Ngorongoro Conservation Area promises an unforgettable adventure. Immerse yourself in the wonders of nature, encounter majestic wildlife, and connect with the rich cultural tapestry of Tanzania.
     Start planning your journey today and prepare to be enchanted by the timeless allure of Ngorongoro..</p>




    </div>

      <Footer></Footer>
    </div>
  );
}

export default Ngorongoro;