
import React from "react";
import Masonry from "react-responsive-masonry";
import group1 from '../../../assets/group/gallery-01.webp';
import group2 from '../../../assets/group/gallery-02.webp';
import group3 from '../../../assets/group/gallery-03.webp';
import group4 from '../../../assets/group/gallery-04.webp';
import group5 from '../../../assets/group/gallery-05.webp';
import group6 from '../../../assets/group/gallery-06.webp';
import group7 from '../../../assets/group/gallery-07.webp';
import group8 from '../../../assets/group/gallery-04.webp';
import group9 from '../../../assets/group/IMG_2420.jpg';
import group10 from '../../../assets/group/IMG_5104.jpg';
import group11 from '../../../assets/group/IMG_5202.jpg';
import group12 from '../../../assets/group/IMG_5372.jpg';
import group13 from '../../../assets/group/IMG_5728.jpg';
import group14 from '../../../assets/group/IMG_5563.jpg';



const images = [
    group1,
    group9,
    group7,
    group10,
    group5,
    group14,
    group13,
    group11,
    group12,
    group8,
    group3,
    // Add other images here
];

class ShareStory extends React.Component {
    render() {
        return (
            <div style={{backgroundColor:'white', marginTop:'5%'}}>
                <h1 style={{ textAlign: "center", padding: "30px", lineHeight:'normal' }}>Memories Captured: The Beauty of Diversity in a Single Frame!</h1>
                <Masonry columnsCount={3} gutter="10px">
                    {images.map((image, i) => (
                        <div className="image-container" key={i}>
                            <img src={image} alt={`Image ${i}`} />
                            <div className="overlay">
                                {/* <p>Your Overlay Text</p> */}
                            </div>
                        </div>
                    ))}
                </Masonry>
            </div>
        );
    }
}

export default ShareStory;

