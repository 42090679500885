import React, {useEffect} from 'react';
import ReactGA from 'react-ga4'; // Import react-ga
import Header from "../../components/header/header";
import Intro from "./introduction/intro";
import Partner from "./partners/partners";
import Speaker from "./speaker/speaker";
import UpcomingEvent from "./platform/platform";
import ShareStory from "./share-story/share_story";
import Footer from "../../components/footer/footer";


import './home_page.css'

function Homepage()    {

  useEffect(() => {
    ReactGA.initialize('G-5KY4YDTVED');
    ReactGA.send({ 
      hitType: "pageview", 
      page: window.location.pathname + window.location.search, 
      title: "Home page"
     });
  }, []);


    return (
      <div className="homepage">
          <Header></Header>
          <Intro></Intro>
          <Speaker></Speaker>
          <UpcomingEvent></UpcomingEvent>
          <ShareStory></ShareStory>
          <Partner></Partner>

          <Footer></Footer>
      </div>
  );
}
  export default Homepage;