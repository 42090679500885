import React from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import aboutus from '../../assets/group/IMG_5085.jpg'
import './aboutus.css'
import { Carousel } from 'react-responsive-carousel';
import Caroussel from 'react-multi-carousel';
import zeitun from '../../assets/img/zeitun.webp'
import susan from '../../assets/img/susan.webp'
import lady from '../../assets/img/lady.webp'
import wanyama from '../../assets/img/wanyama.webp'



function aboutUs() {

  window.scrollTo(0, 0);


    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };
    return (
      <div className="FAQPage">

        <Header></Header>

        <div className='faq-banner'> 
        <img src={aboutus} alt="Destination"/>
        <div class="centered">

            {/* <h1 style={{ fontSize:'3rem', lineHeight:'normal', color:'white'}}>About Us;</h1> */}
            </div>

        </div>




        <div className="aboutus-content">
            <p style={{marginTop:'2%'}}>At Edgeway Travel we´re not just travel consultants; we´re architects of  extraordinary journeys. We believe true travel transcends sightseeing and
               souvenirs-it´s about forging deep connections with vibrant cultures, immersing yourself in breathtaking landscapes, and returning home with memories that
                resonate for a lifetime.</p>
            <p style={{marginTop:'2%'}}>Forget the one-size-fits-all approach. We meticulously craft bespoke itineraries tailored to your unique desires, interests and pace. Whether you crave a solo
               prilgrimage to ancient ruins or a family adventure through bustling markets, we translate your travel dreams into meticulously curated experiences.</p>
            <p style={{marginTop:'2%'}}> We navigate you beyond the well-trodden tourist trail, unveiling hdden gems that whisper tales of local culture and history. From serene coastal villages to
               bustling street food markets, we connect you with the souls of each destination, offering authentic encounters that enrich your perspective.</p>  
            <p style={{marginTop:'2%'}}>From meticulously arranged logistics to expert-curated recommendations, we handle every detail with meticulous attention. Imagine seamless flight bookings,
                comfortable accomodations, and insider tips delivered effortlessly, allowing you
                 to truly immerse yourself in the magic of exploration.</p>    
            <p style={{marginTop:'2%'}}>Our goal is not just to send you on a trip, it´s to inspire you with transformative experiences that stay with you long after you return hoem. We craft journeys
              that ignite your wanderlust, broaden your horizons, ans shape your narrative with captivating stories you´ll cherish forever.</p>     
        </div>

        <div class="about-centered" style={{textAlign:'center'}}>

           <h1 style={{ fontSize:'3rem', lineHeight:'normal'}}>Our mission and goals</h1>
        </div>

        <div className='public-cards'>
                <div className='public-containers' style={{ }}>
                  <h4 style={{width:'100%', color:'#761034'}}> Our Mission</h4>
                  <p style={{width:'100%', marginTop:'3%'}}> To empower individuals and organizations to embark on transformative
                    journeys that transcend the ordinary, unlock cultural immerson, and ignite personal growth. </p>
                </div >
                <div  className='public-containers'  style={{marginTop:'1rem'}}>
                  <h4 style={{color:'#761034'}}> Our Founding Principle</h4>
                  <p style={{marginTop:'3%'}}> Edgeway Travel was founded with a vision for exploration, dedicated to
                       delivering masterful travel experiences. With unwavering commitment and
                        unparalleled expertise, we navigate the complexities of travel, allowing you
                              to focus on the joy of discovery</p>
                </div>


              </div>

              <div class="about-centered" style={{textAlign:'center'}}>

               <h1 style={{ fontSize:'3rem', lineHeight:'normal'}}>Our core values </h1>
               </div>
        <div className='finance-cards'>
                <div className='finance-containers'>
                  <h3 style={{width:'100%'}}>Integrity</h3>
                  <p style={{marginTop:'5%'}}> We operate with the highest ethical standards and integrity in all our interactions.</p>

                </div >
                <div  className='finance-containers'  >
                  <h3>Excellence</h3>
                  <p style={{marginTop:'5%'}}> We are committed to delivering excellence in every aspect of our consulting services.</p>

                </div>
                <div  className='finance-containers'  >
                 <h3>Innovation</h3>
                 <p style={{marginTop:'5%'}}>We embrace creativity and innovation to provide cuttingedge solutions.</p>

                </div>

                  <div  className='finance-containers'  >

                  <h3>Accountability</h3>
                  <p style={{marginTop:'5%'}}>We accept to be held accountable for the decisions we make.</p>

                </div>

              </div>


              



              <div className="zq-articles-card-sect">
        <h2 style={{marginBottom:'1rem', textAlign:'center'}} >Client Testimonials</h2>
        <Caroussel responsive={responsive} >

          <div className="zq-articles-card">
            <img src={susan} alt="articles"  className="zq-articles-card-pic" />
            <span>Zaituni - kilifi</span>
            <p>Edgeway Travel made our safari experience unforgettable! From the moment we arrived, everything was taken care of seamlessly. 
                The guides were knowledgeable, the accommodations were fantastic, and the wildlife sightings were incredible. Thank you, Edgeway Travel, for an amazing adventure</p>
          </div>

          <div className="zq-articles-card" >
            <img src={wanyama} alt="articles"  className="zq-articles-card-pic"/>
            <span>Wanyama - Mombasa </span>
            <p>I cannot recommend Edgeway Travel enough! They helped me plan a customized trip that perfectly suited my interests and budget. The team was attentive, responsive,
                 and went above and beyond to ensure I had an incredible experience exploring Kenya's diverse landscapes and vibrant culture.</p>
          </div>

          <div className="zq-articles-card">
            <img src={zeitun} alt="articles"  className="zq-articles-card-pic" />
            <span>Susan - Kisumu</span>
            <p>My family and I had the most wonderful time with Edgeway Travel on our recent vacation to Kenya. From the breathtaking views of Mount Kenya to the stunning beaches of Diani,
                 every moment was filled with awe and wonder. Thank you to the entire team for making our trip truly memorable</p>
          </div>

          <div className="zq-articles-card">
            <img src={lady} alt="articles"  className="zq-articles-card-pic" />
            <span>Nosim - Samburu</span>
            <p>Edgeway Travel exceeded all my expectations! From the moment I inquired about their services to the end of my trip, their team was professional, friendly, and attentive to my needs.
                 They helped me discover hidden gems in Kenya that I never would 
                 have found on my own. I can't wait to book my next adventure with Edgeway Travel.</p>
          </div>


        </Caroussel>
      </div>

        <Footer></Footer>
      </div>
    );
  };
  
  export default aboutUs;